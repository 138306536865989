@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.forgot__password__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  height: 100vh;

  .form__group__input.invalid {
    border: 1px solid #FF3939;
  }


  .forgot__password__logo__container {
    margin-bottom: 103px;
  }

  .form__container__forgot__password {
    width: 100%;
  }

  .form__group__label {
    display: flex;
    justify-content: space-between;
  }

  .forgot__password__form {
    width: 100%;
  }

  .form__group__btn__container {
    margin-top: 31px;

    .back {
      display: flex;
      justify-content: center;
      @include font(Inter, 500, 16px, 24px);
      cursor: pointer;
    }

    width: 100%;

    button {
      width: 100%;
      @include font(Inter, 500, 16px, 24px);
      color: #FFF;
      border: none;
      border-radius: 8px;
      padding: 12px 20px;
      background: #111;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
      margin-bottom: 20px;
      transition: ease-in 0.5s;

      &:hover {
        background: #0E91AF;
      }
    }
  }

  .forgot__password__text__container {
    margin-bottom: 71px;
    @include font(Inter, 700, 25px, normal);
  }

  .form__group {
    width: 100%;
    display: flex;
    flex-direction: column;

    .error-message {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      color: #FF3939;
      @include font(Inter, 500, 13px, 24px);
    }

    label {
      @include font(Inter, 500, 14px, 20px);
      color: #344054;
    }

    input {
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      background: #F9F9F9;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      @include font(Inter, 400, 16px, 24px);

      &.empty {
        border-color: #FF3939;
      }

      &.invalid {
        border-color: #FF3939;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .forgot__password__container {
    padding: 0 20px;
  }
}

