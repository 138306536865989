@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$INPUT-COLOR-LOGIN: #000;

.btn__group__edit__info{
  display: flex;
  flex-direction: column;
  gap: 14px;

  .btn__save__edit__info{
    border-radius: 8px;
    background: #111;
    color: #fff;
    padding: 12px 97px;
    @include font('Inter', 600, 15px, 24px);
    cursor: pointer;
    transition: ease-in 0.3s;

    &:hover{
      background: #0E91AF;
    }

  }

  .btn__close__edit__info{
    color: #111;
    border-radius: 8px;
    background: #FFF;
    padding: 12px 97px;
    @include font('Inter', 500, 16px, normal);
    cursor: pointer;
    transition: ease-in 0.3s;

    &:hover{
background: #BBBBBB;
    }
  }
}
/* Стилі для затемнення фону */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Стилі для самого модального вікна */
.modal {
  border-radius: 8px;
  background: #F4F4F4;
  padding: 20px;
  width: 90%; /* Ширина модального вікна */
  max-width: 520px; /* Максимальна ширина модального вікна */
  max-height: 80vh; /* Максимальна висота модального вікна */
  overflow-y: auto; /* Додавання вертикальної прокрутки при необхідності */
  input {
    margin-top: 6px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: white;
    padding: 12px 16px;
    outline: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @include font(Inter, 400, 16px, 24px);
    color: $INPUT-COLOR-LOGIN;
    cursor: pointer;
    transition: all 0.2s;

  }
  input:hover{
    border-color: #636366;
  }
}


.modal__head__personal__info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include font('Inter', 600, 16px, 24px);
  }

  .close {
    cursor: pointer;
    color: #111;

    svg{
      height: 20px !important;
  width: 20px !important;
    }
  }
}

.btn__group__edit__info{
  button{
    border: none;
  }
}