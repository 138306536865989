@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }


.add-drivers-modal__add_driver__dis {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 14px 20px;
  border-radius: 8px;
  background: #F4F4F4;
  z-index: 1000;

  .react-datepicker-wrapper{

    input {
      width: 100%;
    }
  }
}

.backdrop__add_driver__dis {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-drivers-modal__add_driver__dis{
  width: 100%;
  max-width: 520px;
  max-height: 90vh;
  overflow-y: auto;
}

.btn__register__driver{
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  background: #111;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  @include font("Inter", 600, 15px, 24px);
  transition: ease-in 0.5s;

  &:hover{
    background: #0E91AF;
  }
}

.add-drivers-modal__close{
  cursor: pointer;
  svg{
    width: 30px;
    height: 30px;
  }
}

.btn__cancel_reg__driver{
  cursor: pointer;
  border: none;
  padding: 13px 134px 12px 133px;
  border-radius: 10px;
  background: #FFF;
  @include font("Inter", 500, 16px, normal);
  transition: ease-in 0.5s;

  &:hover{
    background: #BBBBBB;
  }

}

.add-drivers__form__btn__group{
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.add-drivers-modal__header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @include font("Inter", 600, 16px, 24px);
}

.error-message-password{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  border-radius: 6px;
  background: #FFF;
  padding: 12px 32px 12px 14px;
  color: #FF3939;
  align-items: center;
  gap: 6px;
  @include font("Inter", 600, 14px, 24px);
;
}
.add-drivers__form__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 6px;

  label {
    @include font("Inter", 500, 14px, 20px);
    display: flex;
    justify-content: space-between;

    .error-message {
      color: red;
      margin-left: 5px;
      display: flex;
      align-items: center;
      gap: 6px;

    }
  }

  input {
    padding: 10px 14px;
    border-radius: 8px;
    background: #FFF;
    @include font("Inter", 600, 16px, 24px);
    outline: 0;
    border: 1px solid  #D0D5DD;

  }
}

.password-input-container {
  position: relative;
  width: 100%;
  input {
    width: 100%;
  }
  .password-visibility-toggle {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}


