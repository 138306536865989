.main__page__driver{
  display: flex;
}

.main__driver__container{

}

@media screen  and (max-width: 1024px) {
  .main__page__driver{
      display: block;
  }
}