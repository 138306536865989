@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
    
}

.load__more__loads{
  display: flex;
  justify-content: center;

  .btn__load__more__loads{
    @include font(Inter, 600, 16px, 24px);
    cursor: pointer;
    color: #FFFFFF;
    background: #111111;
    padding: 5px 30px;
    border-radius: 8px;
    transition: all 0.5s ease-in-out;

    &:hover{
      background-color: #0E91AF;
    }
  }
}
.approve-pending {
  background-color: #0E91AF;
}
.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

// LOADS
.one__load__first__state,
.one__load__last__state{
  @include font(Inter, 600, 16px, 24px);
}

.one__load__first__street,
.one__load__last__street,
.one__load__first__zip,
.one__load__last__zip{
  @include font(Inter, 500, 12px, normal);
  color: #676767;
}

.one__load__first__date,
.one__load__last__date{
  @include font(Inter, 500, 15px, normal);
}

.one__load__first__time,
.one__load__last__time{
  @include font(Inter, 500, 13px, normal);
  color: #676767;
}

.one__load__labels{
  @include font(Inter, 300, 14px, normal);
}
.one__load__values{
  @include font(Inter, 600, 14px, normal);
}
.one__load__driver__name{
  @include font(Inter, 500, 14px, normal);
}
.one__load__driver__mc{
  @include font(Inter, 500, 12px, normal);
  color: #0E91AF;
}
.one__load__driver__num__em__comp{
  @include font(Inter, 500, 12px, normal);
  color: #646675;
}
.one__load__photos__pickup__label,
.one__load__photos__delivery__label{
  @include font(Inter, 600, 16px, normal);
  color: #969696;
  margin: 15px 0px;
}

.one__load__photos__delivery__buttons,
.one__load__photos__pickup__buttons{
  @include font(Inter, 700, 16px, normal);
}

.one__load__statuses{
  @include font(Inter, 500, 13px, normal);
}




.one__load__container {
  background-color: white;
  border-radius: 8px;
  padding: 25px 25px 40px 25px;
  margin-bottom: 20px;
  }
  .one__load__statuses__more {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  }
  .one__load__statuses {
  display: flex;
  gap: 20px;
  }

  .one__load__first__place__svg {
  display: flex;
  }
  .one__load__frist__place__time {
  display: flex;
  padding-bottom: 20px;
  border-left-style: dashed;
  border-width: 3px;
  justify-content: space-between;
  border-color: #7C7C7C;
  }

  .one__load__first__place {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  }
  .one__load__first__state {
  margin-bottom: 5px;
  }
  .one__load__first__date {
  margin: 4px 5px 7px 0px;
  }
  .one__load__last__state {
  margin-bottom: 5px;
  }
  .one__load__driver__details {
  display: flex;
  gap: 15px;
  gap: 11px;
  }
  .one__load__driver__name__mc {
  display: flex;
  flex-direction: column;
  gap: 5px;
  }
  .one__load__driver__num__em__comp {
  flex-direction: column;
  gap: 5px;
  }
  .one__load__last__place__time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  }
  .one__load__additional__details {
  border-left-style: dashed;
  border-width: 3px;
  padding-bottom: 20px;
  padding-left: 22px;
    display: flex;
    gap: 20px;
    border-color: #7C7C7C;
  }
  .one__load__first__svg svg {
  position: relative;
  right: 11px;
  background-color: white;
  width: 20px;
  height: 20px;
  }
  .one__load__last__svg svg {
    position: relative;
    right: 11px;
    background-color: white;
    width: 25px;
    height: 25px;
    }

    .one__load__labels {
      display: flex;
      flex-direction: column;
      gap: 5px;
      }
      .one__load__values {

      display: flex;
      flex-direction: column;
      gap: 5px;
      }

      .one__load__driver__svg{
        display: flex;
            padding-top: 5px;
        svg{
          position: relative;
          right: 14px;
          color: #0E91AF;
          background-color: #F1F6FF;
          border-radius: 100%;
          stroke-width: 1px;
          width: 30px;
          height: 30px;
          padding: 5px;
          
        }
      }

      .one__load__status {
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        padding: 2px 10px;
        }
  .one__load__details__photos__container {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    }
    .one__load__photos {
    border-left-style: solid;
    border-width: 1px;
    padding-left: 40px;
    border-color: #EAEAEA;
    margin-left: 50px;
    width: 100%;
    }

    .one__load__photos__pickup__img__container {
      // display: grid;
      display: flex;
      justify-content: flex-end;
      gap: 40px;
      // grid-template-columns: repeat(4, 1fr);
      margin-bottom: 10px;

      }
      .one__load__photos__pickup__img {
        @include font(Inter, 600, 13px, normal);
     
      height: 100px;
      }

      .one__load__photos__pickup__img img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      }




      .one__load__photos__delivery__img__container {
      // display: grid;
      display: flex;
      justify-content: flex-end;
      gap: 40px;
      // grid-template-columns: repeat(4, 1fr);
      margin-bottom: 10px;
        }
        .one__load__photos__delivery__img {
        width: 100px;
        height: 100px;
        }

        .one__load__photos__delivery__img img {
        width: 100%;
        height: 100%;
        }

        .one__load__photos__pickup__buttons,
        .one__load__photos__delivery__buttons {
          display: flex;
          gap: 20px;
          justify-content: flex-end;
          color: #969696;
          }
          .one__load__photos__pickup__button__cancel,
          .one__load__photos__delivery__button__cancel {
          color: black;
          border-style: solid;
          border-radius: 8px;
          border-width: 1px;
          padding: 5px 14px;
          width: 140px;
          text-align: center;
          cursor: pointer;
          transition: all 0.1s ease-in-out;
          }
          .one__load__photos__pickup__button__cancel {
            color: black;
          border-style: solid;
          border-radius: 8px;
          border-width: 1px;
          padding: 5px 14px;
          width: 140px;
          text-align: center;
          cursor: pointer;
          transition: all 0.1s ease-in-out;
          }
          .one__load__photos__pickup__button__cancel:hover,
          .one__load__photos__delivery__button__cancel:hover{
            color: white;
            background-color: black;
          }
          
          .one__load__photos__pickup__button__approve,
          .one__load__photos__delivery__button__approve {
          border-style: solid;
          border-radius: 8px;
          border-width: 1px;
          padding: 5px 14px;
          width: 140px;
          text-align: center;
          cursor: pointer;
          }
          .one__load__more{
            cursor: pointer;
            svg{
                width: 25px;
                height: 25px;
            }
          }
          



          .one__load__driver__name__num {
            display: flex;
            }
            .one__load__driver__mc__em {
            display: flex;
            }
            .one__load__driver__details__outer__container {
            display: flex;
            align-items: baseline;
            }
            .one__load__driver__em {
            margin-bottom: 6px;
            }

            .one__load__driver__num {
            margin-bottom: 6px;
            }

.one__load__modal{
    position: absolute;
    width: 4%;
    z-index: 9999; 
}

.one__load__dimensions__value{
    span{
        @include font(Inter, 300, 14px, normal);
    }
}
.one__load__status__svg{
  display: flex;
  svg{
    width: 23px;
    height: 23px;
    color: #0E91AF;

  }
}
.vvv{
  position: relative;
  cursor: pointer;
  svg{
    width: 20px;
    height: 20px;
  }
}
.one__load__status__hide{
  cursor: pointer;
}
.modal__edit__load {
  position: absolute;
  width: 4%;
  z-index: 9999; 

  .modal-content__edit__load {
    right: -25px;
    top: -30px;
    position: absolute;
    background: #fff;
    padding: 11px;
    border-radius: 8px;
    padding-right: 20px;
    z-index: 10000;
    gap: 5px;
    width: 179px;

    @include font(Inter, 500, 16px, 1.7);
    display: inline-flex;

    flex-direction: column;
    align-items: flex-start;

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

  }

  .more-action-item {
    cursor: pointer;
  }
}

.d__menu__item__load{
  cursor: pointer;
transition: all 0.1s ease-in-out;
}
.d__menu__item__load:hover{
  color: #0E91AF;
}
.modal-content__file{
  @include font(Inter, 500, 14px, 1.7);
  color: #646675;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #EAEAEA;
  margin-bottom: 15px;
}
.modal-content__file__container{
  display: flex;
  gap: 10px;
  svg{
    width: 22px;
    height: 22px;
  }
}
.modal-content__file__name{
  cursor: pointer;
  text-decoration: underline;
}

.alternative__images{
  display: flex;
  width: 100%;
justify-content: space-around;
}
.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}

.accepted-status{
  border-style: solid;
          border-radius: 8px;
          border-width: 1px;
          padding: 1px 14px;
          width: 140px;
          text-align: center;
          cursor: pointer;
  display: flex;
  gap: 20px;
  justify-content: center;
  color: #3F885A;
  @include font(Inter, 600, 16px, 1.7);
  display: flex;
  

}
.one__load__photos__delivery__button__approve.approve-pending{
  color: #0E91AF;
  background-color: white;
  transition: all 0.1s ease-in-out;

}
.one__load__photos__pickup__button__approve.approve-pending{
  color: #0E91AF;
  background-color: white;
  transition: all 0.1s ease-in-out;

}

.one__load__photos__delivery__button__approve.approve-pending:hover{
  color: white;
  background-color: #0E91AF;
}
.one__load__photos__pickup__button__approve.approve-pending:hover{
  color: white;
  background-color: #0E91AF;
}

.one__load__details{
  width: 80%;
}
.one__load__photos__delivery__label {
  margin-bottom: 15px;
  }
  .one__load__photos__delivery__buttons {
  margin-top: 15px;
  }