@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.DropDownOptionsContainer {
  padding: 30px;
  width: 100%;
  height: 100svh;
  overflow-y: auto;

  .DropDownOptionsBtnContainer {
    display: flex;
    
    margin-bottom: 56px;
    height: 35px;
  }

  .DropDownOptionsBtnTruck {
    @include font(Inter, 600, 18px, 24px);
    border-radius: 8px;
    
    cursor: pointer;
    display: flex;
width: 220px;
padding: 6px 30px;
justify-content: center;
align-items: center; 
  }

  .DropDownOptionsBtnValAdd{
    @include font(Inter, 500, 14px, 24px);
    color: #0E91AF;
    cursor: pointer;
    border-radius: 7px;
    border: 1px solid #0E91AF;
    height: 34px;
    padding: 8px 20px;
    width: 110px;
justify-content: center;
align-items: center; 
    transition: ease-in-out 0.2s;
    margin-left: 161px;
    transition: all 0.2s ease-in-out;
display: flex;
background-color: white;
    &:hover{
      color: #FFF;
      border-radius: 7px;
      border: 1px solid #0E91AF;
      background: #0E91AF;
    }

  }

  .DropDownOptionsBtnTrailer {
    @include font(Inter, 600, 18px, 24px);
    border-radius: 8px;
    
    cursor: pointer;
    display: flex;
width: 220px;
padding: 6px 30px;
justify-content: center;
align-items: center; 
transition: all 0.2s ease-in-out;
  }
}

.active__drpdwn{
  background: white;
}