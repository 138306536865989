@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }

.driver__profile__page{
    display: flex;
    width: 100%;
    background: #EFF0F2;
    
}

.driver__documents__container{
    border-radius: 10px;
  background: #DEE0E3;
  padding: 14px;
  position: relative;
top: 50%;
}
.driver__documents__container{
.drivers__profile__docyments__select{
display: block;
}
}
.select__documents{
    display: flex;
    justify-content: flex-end;
  margin-bottom: 20px;
}
.drivers__profile__component__content{
    h1{
        @include font(Inter, 700, 25px, normal);
    }
}

@media screen and (max-width: 1024px) {
    .driver__profile__page{
        display: block;
    }
    
}

@media screen and (max-width: 756px) {
    h1{
        display: none;
    }

    .select__documents,
    select {
  width: 100%;
}
}
.custom-select.driver_profile{
  background-color: white;
  border-radius: 8px;
  padding: 5px 14px;
}

.options-list.driver_profile{
  width: 260px;
right: 0;
}