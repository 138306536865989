@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$BGMENU-COLOR: #111111;
$MENU-ITEM-COLOR: #F6F6F6;
$MENU-ITEM-HOVER-COLOR:#5CC3B6;
$LINE-COLOR:#B3B3B3;

.logout__item{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.logout__text{
  color: $MENU-ITEM-COLOR;
  @include font(Geologica, 600, 15px, 150%);
  position: relative;

}

.navigation__profile{
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid $LINE-COLOR;

  .profile__photo__nav__menu{
    width: 50px;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


// Navigation.scss
.menu__container{
  width: 100%;
}
.menu__link__profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.name__profile{
  @include font(Inter, 600, 15px, 150%);
  color: $MENU-ITEM-COLOR;
}
.navigation__dispatcher {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color:  $BGMENU-COLOR;
  width: 100%;
  max-width: 128px;
  height: 100vh;
padding: 30px 0;
}

.navigation__menu{
  width: 100%;
  margin-top: 30px;
}

.menu__link{
  width: 100%;
  display: flex;
 justify-content: space-between;
  text-decoration: none;
  span{
    color:$MENU-ITEM-COLOR;
    @include font(Geologica, 500, 15px, 150%);
  }
}


.menu__item{
  color: red;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  &:hover{

  }
}

.left__menu__item{
  span{
    @include font(Geologica, 500, 15px, 150%); 
    position: relative;
    width: 100%;
    text-align: center;

  }
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $MENU-ITEM-COLOR;
}

.item__left__side,
.item__right__side{
  width: 4px;
  height: 100%;
  background-color: #5CC3B6;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.item__left__side.active,
.item__right__side.active
{
  opacity: 1;
}

.item__left__side{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.item__right__side{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}




.menu__icon{
  font-size: 27px;
}

.menu__item {
  .menu__link {
    .left__menu__item {
      .menu__icon,
      .menu__title {
        display: flex;
        justify-content: center;
        transition: color 0.3s ease;
      }
    }

    &:hover,
    &.active,
    &.current {
      .left__menu__item {
        .menu__icon,
        .menu__title {
          color: $MENU-ITEM-HOVER-COLOR;
        }
      }
    }
  }
}



.navigation__dispatcher__mobile{
  display: none;
  width: 100%;
  background: #111;
  position: sticky;
  top: 0;
z-index: 9;
}

.mobile__menu{
  display: none;
  position: fixed;
  top: 0;
  z-index: 2;
  background: #111;
  width: 80%;
  height: 100vh;
}

.mobile__menu__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}
.mobile__menu__logo > img:nth-child(1) {
  height: 70px;
  margin-top: 20px;
}

/* Стилі для мобільних пристроїв */
@media screen and (max-width: 1024px) {
  .left__menu__item{
    flex-direction: row;
    span{
      right: 50px;
      font-size: 20px;
    }
  }
  .profile__container{
    flex-wrap: wrap;
  }
.navigation__dispatcher{
  display: none;
}
  .navigation__dispatcher__mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  .mobile__menu{
    display: block;
  }

  .menu__without__panels{
    display: flex;
  }

  .mobile__menu__container{
    svg {
      position: relative;
      right: 65px;
      }
    }
    .logout__item__mobile{
      
      img{
        position: relative;
        right: 41px;
        
      }
      .logout__text{
        right: 46px;
font-size: 20px;
font-weight: 500;
      }
  }

  .mobile__items__menu > div:nth-child(2) > a:nth-child(1) > div:nth-child(2) > div:nth-child(1) > span:nth-child(2) {
    position: relative;
    left: -3px;
  }
  

  .menu__link{
    position: relative;
    margin-bottom: 30px;
  }

  .mobile__items__menu > div:nth-child(2) > a:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > svg:nth-child(1) {
    right: 18px;
  }

  .mobile__items__menu > div:nth-child(2) > a:nth-child(1) > div:nth-child(2) {
    left: -7px;
    position: relative;
  }
  
  .item__left__side.active,
  .item__right__side.active {
    opacity: 1;
    height: 200%;
    position: relative;
    bottom: 20px;
    width: 8px;
  }

  .logout__item__mobile{
    position: relative;
    top: 5vh;
  }
}
@media screen and (max-height: 811px) {
.logout__item__mobile{
  top: -1vh;
}
}
.mobile__menu {
  display: none;

  &.open {
    display: block;
    z-index: 90;
    overflow-y:auto;
  }
}

.navigation__burger {
  color: #5CC3B6;
  font-size: 46px;
}

.close__mobile__icons{
  color: #5CC3B6;
  font-size: 50px;
}

.close__mobile__icons{
  display: flex;
  justify-content: flex-end;
}

.logout__item__mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
}

.mobile__menu__container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.hidden {
  opacity: 0;
}

.mobile__items__menu > div:nth-child(4) > a:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
  position: relative;
  left: 20px;
}
.mobile__items__menu > div:nth-child(5) > a:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
  position: relative;
  left: 10px;
}
.mobile__items__menu > div:nth-child(6) > a:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
  position: relative;
  left: 30px;
}
