@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
}
.custom-slider.slider-main{
    width: 500px;
   

}
.swiper-wrapper{
@include font('Inter', 600, 16px, 24px);
}
img{}
.swiper-slide.fl__slider.main,
.swiper-slide.fl__slider.main.swiper-slide-active{
    
height: 100%;
    img{
        object-fit: cover !important;
        height: 100px !important;
        border-radius: 8px !important;
        width: 100px;
        position: unset;
        }
}

.swiper-button-prev.main{
left: -9px;
}

.swiper-button-next.main{
right: -9px;
}
.swiper__button_container{
video {
    height: 60vh;
    width: 100%;
}
}
.swiper-video{
    height: 100px;
width: 100px;
object-fit: cover;
border-radius: 8px;

}
.swiper-video{
    transition: all 0.2s ease-in-out;
    filter: blur(2px);
}
.swipeer-video-wrapper-icon {
    position: absolute;
    top: 37px;
    left: 55px;
    }
    .swipeer-video-wrapper-icon 
    svg {
    width: 30px;
    height: 30px;
    color: white;
    
    transition: all 0.2s ease-in-out;
    }
    
    .swipeer-video-wrapper-icon {
    
    left: 55px;
    
    left: 49px;
    }
    .swiper-video-wrapper:hover{
        .swiper-video{
            filter: blur(1px);
        }
        svg{
            color: #0E91AF;
        }
    }
    .swiper-video-wrapper {
        width: 100px;
        border-radius: 8px;
        overflow: hidden;
        }

        .swiper-video-thumbnail-wrapper {
            height: 100%;
            }
            .swiper-video-thumbnail {
            height: 100%;
            width: 100%;
            object-fit: cover;
            }
            .swiper-video-thumbnail-wrapper-icon 
            svg {
            width: 30px;
            
            height: 30px;
            position: relative;
            top: -47px;
            left: 21px;
            color: white;
    
            }
            .swiper-video-thumbnail{
             
    filter: blur(2px);
            }
.image-wrapper{
cursor: pointer;
}

.loader.photo {
    width: 25px;
    height: 25px;
    border: 5px solid #0E91AF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 40px;
    left: 38px;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    .loader__backdrop{
        position: absolute;
        background-color: white;
        z-index: 9;
    }

    .loader__backdrop{
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
    }