@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.file-input-container {
position: relative;
  margin-bottom: 70px;
  margin-top: 20px;
}

.deleteFileTrailer{
  position: absolute;
  right: 0;
  top: 0;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload-text {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include font(Inter, 500, 14px, 24px);
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 7px;
  background: #989898;
  cursor: pointer;
}


.modal-overlay__add__files__trailer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Затемнений колір */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content__add__files__trailer {
  width: 100%;
  max-width: 360px;
  border-radius: 8px;
  background: #F4F4F4;
  padding: 14px 20px;
}

.add__files__trailer {
  .form__group__add__files__trailer {
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    

    select {
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      padding: 13px 14px 12px 14px;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    label {
      @include font(Inter, 500, 14px, 20px);
      color: #344054;
    }

    textarea {
      @include font(Inter, 500, 15px, 21px);
      padding: 13px 14px 12px 14px;
      outline: 0;
      resize: none;
      width: 100%;
      height: 109px;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      transition: all 0.2s ease-in-out;
    }
    textarea:hover{
      border-color: black;
      }
  }

  
  

  .btn_add__files__trailer {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .cancel_file__trailer {
      @include font(Inter, 500, 16px, normal);
      border: none;
      border-radius: 10px;
      background: #FFF;
      padding: 13px 134px 12px 133px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .cancel_file__trailer:hover{
      background-color: #BBBBBB;
    }

    .add_file__trailer {
      border-radius: 12px;
      background: #111;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #FFF;
      padding: 10px 128px;
      border: none;
      cursor: pointer;
      @include font(Inter, 600, 15px, 24px);
      transition: all 0.2s ease-in-out;
    }
    .add_file__trailer:hover{
      background-color: #0E91AF;
    }

    .cansel_file__trailer {
      @include font(Inter, 500, 16px, 24px);
      color: #111;
      border: none;
      border-radius: 10px;
      background: #FFF;
      padding: 13px 134px 12px 133px;
      cursor: pointer;
    }
  }

}

.modal-content__head__add__files__trailer{
  @include font(Inter, 600, 16px, 24px);
   margin-bottom: 10px;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   svg{
    width: 25px;
    height: 25px;
   }
}

.date__picker__add__files__trailer {
  width: 100%;
  height: 40px;
  background-color: white;
  border: solid 1px #D0D5DD;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  @include font(Inter, 600, 15px, 20px);
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
  }
  .date__picker__add__files__trailer:hover{
    border-color: black !important;
  }
  .modal-content__close{
    cursor: pointer;
  }

  .error-message{
    color: red;
    @include font(Inter, 500, 14px, 20px);
  }