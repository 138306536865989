@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modal__overlay__forgot__password {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content__forgot__password {
  padding: 14px 20px;
  border-radius: 8px;
  background: #F4F4F4;
  width: 100%;
  max-width: 520px;

  .modal__body__forgot__password{
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    background: #FFF;
    padding: 12px 32px 12px 14px;

    .modal__form__forgot__password__done{
      background: #F2F7FA;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal__text__forgot__password{
      @include font('Inter', 600, 14px, 24px);
      color: #0E91AF;
    }
  }

  .modal__title__forgot__password {
    @include font('Inter', 600, 16px, 24px);
  }

  .modal__header__forgot__password {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .modal__close__forgot__password{
      cursor: pointer;
    }
  }
}


