@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}


.photo__report__wrapper {
  display: flex;
}

.photo__report__container {
  padding: 45px 17px;
  width: 100%;
  background-color: #EFF0F2;
  height: 90svh;
  overflow: auto;

}

.photo__report__return {
  display: flex;
  gap: 10px;
  align-items: center;
  @include font(inter, 500, 20px, normal);
  margin-bottom: 10px;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
    transition: all 0.1s ease;
  }

  svg:hover {
    background-color: #fcfcfc;
  }
}

.photo__report__title {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  @include font(inter, 600, 17px, normal);
  color: #0088A6;

  svg {
    width: 17px;
    height: 17px;
    color: #0088A6;
    background-color: white;
    stroke-width: 13px;
  }
}

.photo__report__location {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.photo__report__time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.photo__report__load {
  display: flex;
  justify-content: space-between;
}

.photo__report__content {
  background-color: white;
  border-radius: 8px;
  padding: 14px 23px;
  margin-bottom: 50px;
}


.photo__report__location__state {
  @include font(inter, 600, 16px, normal);
}

.photo__report__location__street,
.photo__report__location__zip {
  @include font(inter, 500, 12px, normal);
}

.photo__report__time__date {
  @include font(inter, 500, 15px, normal);
}

.photo__report__time__time {
  @include font(inter, 500, 11px, normal);
  color: #676767;
}

.photo__report__load__labels {
  @include font(inter, 300, 15px, normal);
}

.photo__report__load__values {
  @include font(inter, 600, 15px, normal);
}

.photo__report__load__value {
  @include font(inter, 600, 15px, normal);

  span {
    @include font(inter, 300, 15px, normal);

  }
}

.photo__report__button__upload {
  @include font(inter, 600, 16px, normal);
}

.photo__report__button_u {
  color: white;
  display: flex;
  align-content: center;
  gap: 20px;
  background-color: #0E91AF;
  width: 306px;
  height: 36px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease;
}

.photo__report__button_u:hover {
  background-color: #0088A6;
}

.photo__report__button_c {
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: black;
  background-color: white;
  width: 305px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s ease;
}

.photo__report__button_c:hover {
  background-color: rgb(250, 250, 250);
}

.photo__report__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

}

.photo__report__title__svg {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .photo__report__wrapper {
    display: block;
    flex-direction: column;
  }
}

.custom-photo-input {
  position: relative;
  overflow: hidden;
  color: #0E91AF;
  width: 305px;

}

.custom-photo-input input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;

}

.photo__report__file-previews {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  gap: 16px;
  margin-bottom: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}


.photo__report__file-preview {
  // width: 200px;
  height: 200px;
}

.upload-photo-label {
  @include font(inter, 600, 16px, normal);
  position: relative;
  background-color: #0E91AF;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 5px;
}

.photo__report__button_s.enabled {
  background-color: #0D91AF;
  border-radius: 8px;
  padding: 10px 30px;
  color: white;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 16px;
  width: 300px;
  line-height: normal;
  cursor: pointer;
  text-align: center;
}

.title__add__photoreport {

  @include font(inter, 600, 16px, normal);

}

.svg__add__photoreport__driver {

  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  svg {
    width: 62px;
    height: 62px;
  }
}

span.closel__add__photoreport__driver {
  position: relative;
  bottom: 15px;
  left: 15px;
}

.modal__add__photoreport__driver {
  display: none;
  position: fixed;
  background-color: rgba(125, 125, 125, 0.70);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal-content__add__photoreport__driver {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 8px;
}

.close__add__photoreport__driver {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.photo__report__file-previews video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}