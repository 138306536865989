@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.reset__password__container {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .reset__password__button__cancel {
    @include font(Inter, 500, 16px, 24px);
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .reset__password__button__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 31px;

    .reset__password__button {
      @include font(Inter, 500, 16px, 24px);
      color: #FFFFFF;
      border: none;
      border-radius: 8px;
      background: #111;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 12px 20px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }

  .reset__password__form__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 31px;
  }

  .form__group__reset__password {
    display: flex;
    flex-direction: column;
    position: relative;


    label {
      @include font(Inter, 500, 14px, 20px);
      color: #344054;
    }

    input {
      @include font(Inter, 400, 16px, 24px);
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      background: #F9F9F9;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 12px 16px;
    }

    .password-toggle {
      position: absolute;
      top: 70%;
      right: 16px;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 18px;
    }
  }

  .reset__password__logo__container {
    display: flex;
    justify-content: center;
  }

  .reset__container__user__email {
    display: flex;
    justify-content: center;
    @include font(Inter, 400, 16px, 24px);
    margin-bottom: 28px;
  }

  .reset__password__text__container {
    display: flex;
    justify-content: center;
    @include font(Inter, 700, 25px, normal);
    margin-bottom: 29px;
  }

  .reset__password__logo__container {
    margin-bottom: 80px;
  }
}

.password__conditions {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.20);
  width: 100%;
  top: 100%;
  z-index: 2;

  .password__condition__invalid {
    padding: 10px;
  }

  .password__condition {
    &__valid {
      color: green;
      padding: 10px;
    }

    &__invalid {
      color: red;
    }
  }
}

@media screen and (max-width: 1024px) {
  .reset__password__container {
    padding: 0 20px;
  }
}