@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }


  .info__container {
    width: 100%;
    max-width: calc(100% - 600px);
    background-color: #DEE0E3;
  }
  
.buttons__container {
  margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    button{
        @include font(Inter, 600, 18px, normal);
        background-color: #DEE0E3;
            border-style: none;
            border-radius: 8px;
            padding: 6px 51px;
            transition: background-color 0.3s ease;
      cursor: pointer;
    }
  }

  .selected{
    background-color: white !important;
  }

  .driver__data__not__found{
    background-color: #DEE0E3;
    @include font(Inter, 600, 18px, 21px);
    color: #313131;
    padding-top: 15%;
    width: 100%;
    text-align: center;
  }

  .driver__data__not__found__logo{
    margin-top: 50px;
  }

  .info__display{
    height: 90vh;
    overflow: auto;
  }