@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.trucks__table__body__item__img {
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
  }
}

.modal__edit__track {
  position: absolute; /* Відмова від фіксованої позиції */
  width: 4%;
  z-index: 9999;


  .more-action-item {
    cursor: pointer;
  }
}

.vvvv {
  position: relative;
}

.hidden {
  display: inline-block;
}
 