.dispatcher__main__container{
  display: flex;
}

@media screen  and (max-width: 1024px) {
  .dispatcher__main__container{
      display: block;
  }
}

