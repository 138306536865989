@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modalBlockUser {
  border-radius: 10px;
  background: #EFF0F2;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.modalBtnContainerBlockUser {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.modalBlockUserBtn {
  border-radius: 8px;
  background: #111;
  color: #FFF;
  @include font('Inter', 600, 16px, 24px);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 12px;
  cursor: pointer;
  transition: ease-in 0.2s;

  &:hover {
    background: #0E91AF;
  }
}

.modalBlockUserBtnClose {
  color: #111;
  border-radius: 8px;
  background: #FFF;
  padding: 15px;
  cursor: pointer;
  transition: ease-in 0.2s;
  @include font('Inter', 500, 16px, 24px);

  &:hover {
    background: #BBB;
  }
}


