@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$LABEL-COLOR-LOGIN: #344054;
$INPUT-COLOR-LOGIN: #000;
$INPUT-COLOR-HOVER-LOGIN: #0E91AF;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login__container {
  display: flex;
}

.login__container {
  height: 100vh;
  overflow: hidden;
}

.left__login__container {
  width: calc(100% - 800px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url("../../assets/img/background-login.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.right__login__container {
  width: 100%;
  max-width: 800px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .right__login__inner {
    height: 100%;
    max-height: 600px;
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .back__site__container {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    color: #111;
    @include font(Inter, 500, 16px, 24px);
    cursor: pointer;

    a {
      @include font(Inter, 500, 16px, 24px);
      text-decoration: none;
      margin-top: 20px;
    }
  }
}
.form__container {
  width: 100%;
  max-width: 480px;
  padding: 15px;

  .form_group__container {
    .password-wrapper {
      position: relative;


      input[type="password"] {
        padding-right: 30px;
      }

      .toggle-icon {
        position: absolute;
        right: 10px;
        top: 58%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    label {
      color: $LABEL-COLOR-LOGIN;
      @include font(Inter, 500, 14px, 20px);
    }


    input {
      margin-top: 6px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: #F9F9F9;
      padding: 12px 16px;
      outline: 0;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      @include font(Inter, 400, 16px, 24px);
      color: $INPUT-COLOR-LOGIN;
      cursor: pointer;
      transition: all 0.2s;

    }
    input:hover{
      border-color: #636366;
    }
  }
}
.form_group__forgot__container{
  font-family: Inter, sans-serif;
  color: #808080;
  text-decoration: underline;
  cursor: pointer;
}
.form_group__forgot__container:hover{
  color: #272727;
}

.form_group__button__container{
  button {
    margin-top: 42px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #111;
    background: #111;
    padding: 12px 20px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #FFF;
    cursor: pointer;
    @include font(Inter, 500, 16px, 24px);
    transition: background 0.3s, box-shadow 0.3s, border 0.3s;
    &:hover {
      background: $INPUT-COLOR-HOVER-LOGIN;
      border-color: transparent;

    }
  }
}


.password__type {
  margin-top: 35px;
}

.form_group__middle__container {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.form_group__remember__container{
  display: flex;
  align-items: center;
  label{
    @include font(Inter, 500, 14px, 20px);
    color: #344054;
  }
}

.invalid-credentials-message{
  display: flex;
  justify-content: center;
  align-items: center;
  @include font(Inter, 500, 13px, 24px);
  border-radius: 6px;
  background: #FEF2F2;
  color: #EF4444;
  padding: 12px 6px;
  margin-top: 10px;
  svg{
    height: 2em;
    width: 2em;
  }
}


.form_group__remember__container {
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #636366;
    border-radius: 3px;
    outline: none;
    position: relative;
    z-index: 0;
    cursor: pointer;


    &:checked {
      &::after {
        content: '';
        width: 5px;
        height: 10px;
        border: 2px solid #fff;
        border-width: 0 2px 2px 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      background-color: #5CC3B6;
      border-color: #5CC3B6;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 3px #5CC3B6;
    }

    & + label {
      margin-left: 8px;
      max-width: 300px;
      overflow: auto;
    }
  }
}

