$BG-BACK-COLOR:#EFF0F2;
$BG-BACK-HOVER-COLOR:#0E91AF;
$TEXT-COLOR-BTN:#d0d5dd;
$BG-COLOR-BTN:#111;
$BG-INPUT-COLOR:#FFF;
$COLOR-NAME:#101828;

@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.title__page__profile{
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: normal;
  align-self: baseline;
  display: flex;
}

.info__profile__container{
  width: 100%;
  max-width: 480px;
}
.info__profile{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $BG-BACK-COLOR;
  min-height: 100%;
  height: 100vh;
  overflow-y: auto;
    

}
.info__profile{
  padding: 45px 38px 39px 38px;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow-y: auto;
}

.title__page__container{

@include font(Inter, 700, 25px, normal);
}
.user__name__profile{
  @include font(Inter, 600, 18px, normal);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.personal__information{
  width: 100%;
  max-width: 480px;
  border-radius: 10px;
  background: #DEE0E3;
  padding: 20px;
}

.password__container{
  width: 100%;
  max-width: 480px;
  border-radius: 10px;
  background: #DEE0E3;
  padding: 20px;
  margin-top: 18px;
}

.user__name__profile{
  color: $COLOR-NAME;
}
@media screen and (max-width: 1023px) {
  .title__page__profile{
    align-self: center;
  }
}

