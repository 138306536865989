@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.photo-report-cancel-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .modal-content {
    padding: 16px;
    border-radius: 12px;
    background: #F4F4F4;
    max-width: 462px;
    width: 100%;
    height: 330px;

    .photo__report__cancel__head{
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

  }
}

#cancelReason {
  width: 430px;
  height: 109px;
  margin-bottom: 25px;
  resize: none;
  border-radius: 8px;
  border: solid 1px #D0D5DD;
  transition: all 0.2s ease-in-out;
  padding: 15px;
  @include font(Inter, 400, 13px, 20px);
  color: #101828;
  }
  #cancelReason:hover{
    border: solid 1px #aaaaaa;
  }
  #cancelReason:focus{
    outline: none;
  }

  .btn__photo__report__cancel__send {
  background-color: black;
  color: white;
  border-radius: 8px;
  height: 44px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  }
  .btn__photo__report__cancel__send:hover{
    background-color: #0E91AF;
  }
  .btn__photo__report__cancel__cansel{
    background-color: white;
    color: black;
    border-radius: 8px;
  height: 44px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  }
  .btn__photo__report__cancel__cansel:hover{
    background-color: #BBBBBB;
  }
.title__modal__cancel__photo__reason{
  @include font(Inter, 500, 14px, 20px);
  color: #344054;
}
.title__modal__cancel__photo{
  @include font(Inter, 600, 16px, 24px);
  color: #000000;
}
.input__modal__cancel__photo {
  margin-top: 10px;
}

.close__modal__cancel__photo{
  cursor: pointer;
  svg{
    width: 25px;
    height: 25px;
    color: #000000;
  }
}