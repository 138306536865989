@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.typeTitleDriverFiles {
  @include font(Inter, 500, 24px, 24px);
  margin: 5px 0;
}
.DropDownOptionsBtnDriverFiles {
  padding: 10px 51px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font(Inter, 600, 18px, 24px);
}

.driverDocymentsBlock {
  border-radius: 8px;
  background: #FFF;
  padding: 14px;
  width: 100%;
  max-width: 300px;

  input {
    border-radius: 8px;
    border: 1px solid  #D0D5DD;
    background:  #FFF;
    padding: 13px 14px 12px 14px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }



  .typeDriverFiles {
    color: #0E91AF;
    margin-bottom: 40px;
    @include font(Inter, 400, 14px, 20px);
  }
}

.DropItemsDriverFiles{
  display: flex;
  justify-content: space-between;
}