@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }


  
  .file-input-container {
  position: relative;
    margin-bottom: 70px;
  }
  
  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .file-upload-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    @include font(Inter, 500, 14px, 24px);
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 7px;
    background: #989898;
    cursor: pointer;
  }
  
  
  .modal-overlay__add__files__truck {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
    display: flex;
    justify-content: center;
    align-items: center;

    .react-datepicker__input-container{
      input {
        width: 100%;
        border-radius: 8px;
        border: 1px solid  #D0D5DD;
        background:  #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 13px 14px 12px 14px;
      }
    }

    .modal-content__head__add__files__truck{
      display: flex;
      justify-content: space-between;

      .add_docyment__title{
        @include font(Inter, 600, 16px, 24px);
        margin-bottom: 20px;
      }

      .close__modal__add__files__truck{

        cursor: pointer;
        svg{
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  
  .modal-content__add__files__truck {
    width: 100%;
    max-width: 360px;
    border-radius: 8px;
    background: #F4F4F4;
    padding: 14px 20px;


  }
  
  .add__files__truck {
    .form__group__add__files__truck {
      display: flex;
      flex-direction: column;

      .error-message{
        color: #FF3939;
        @include font(Inter, 400, 13px, 20px);
        display: flex;
  align-items: center;
  gap: 5px;
      }

      label {
        display: flex;
        justify-content: space-between;
        color: #344054;

        .error-message{
          color: #FF3939;
          @include font(Inter, 400, 13px, 20px);
          display: flex;
  align-items: center;
  gap: 5px;
        }
      }
  
      select {
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        padding: 13px 14px 12px 14px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
  
      label {
        @include font(Inter, 500, 14px, 20px);
      }
  
      textarea {
        @include font(Inter, 500, 15px, 21px);
        padding: 13px 14px 12px 14px;
        outline: 0;
        resize: none;
        width: 100%;
        height: 109px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        transition: all 0.2s ease-in-out;
      }
      textarea:hover{
        border: 1px solid #111;
      }
    }
    .form__group__add__files__truck.file-input-container{
      .error-message{
      justify-content: end;
      }
    }
    .btn_add__files__truck {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 50px;
  
      .cancel_file__truck {
        @include font(Inter, 500, 16px, normal);
        border: none;
        border-radius: 10px;
        background: #FFF;
        padding: 13px 134px 12px 133px;
        cursor: pointer;
      }
  
      .add_file__truck {
        border-radius: 12px;
        background: #111;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #FFF;
        padding: 10px 128px;
        border: none;
        cursor: pointer;
        @include font(Inter, 600, 15px, 24px);
        transition: ease-in-out 0.2s;

        &:hover {
          background: #0E91AF;
        }
      }
  
      .cansel_file__truck {
        @include font(Inter, 500, 16px, 24px);
        color: #111;
        border: none;
        border-radius: 10px;
        background: #FFF;
        padding: 13px 134px 12px 133px;
        cursor: pointer;
      }
    }
  
  }
  .date-picker-truck{
    transition: all 0.2s ease-in-out;
  }
  .form__group__add__files__truck{
    input:hover{
      border: 1px solid #111 !important;
    }
  }