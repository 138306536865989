*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

$NOT-FOUND-COLOR: #000;
$BTN-BG-HOVER-COLOR:#0E91AF;

@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.not__found__container {
  display: flex;
  flex-direction: column;
 height: 100vh;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.nf__img__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 38px;
  margin-bottom: 31px;

  img {
    max-width: 100%;
    height: auto;
  }

}

.nf__text {
  @include font(Inter, 600, 40px, normal);
  color: $NOT-FOUND-COLOR
}

.btn__back {
  a{
    text-decoration: none;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 12px 127px 12px 128px;
    border: transparent;
    border-radius: 12px;
    background: #111;
    width: 100%;
    max-width: 320px;
    color: #FFF;
    @include font(Inter, 600, 15px, 24px);
    cursor: pointer;
    margin-top: 76px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: $BTN-BG-HOVER-COLOR;
    }
  }

}