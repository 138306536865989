@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}



.notification__modal__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 92%;
  background: rgba(125, 125, 125, 0.70);
  z-index: 999;
}

/* Додайте інші стилі для вашого модального вікна тут */


.notification__modal__wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  width: 100%;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);

  .close__notification__modal{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }


  .notification__text {
    .notification__text__location {
      @include font(Inter, 600, 16px, 24px);
    }

    .btn__notification__make {
      border-radius: 10px;
      border: 1px solid #000;
      background: #FFF;
      padding: 14px 20px;
      @include font(Inter, 600, 16px, 24px);
      margin-top: 40px;
      cursor: pointer;
    }

    .notification__text__description {
      display: flex;
      justify-content: center;
      @include font(Inter, 500, 15px, 24px);
      margin-top: 4px;
    }
  }
}
