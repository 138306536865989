@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modal-container_add_driver__doc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop_add_driver__doc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  z-index: 999;
}

.modal_add_driver__doc {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background: #F4F4F4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
  max-width: 360px;
  // max-height: 80vh;
  overflow-y: auto;

  button {
    margin-top: 10px;
    cursor: pointer;
  }
}

.backdrop_add_driver__doc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}


.modal_add_driver__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .modal_add_driver__title {
    @include font('Inter', 600, 16px, 24px);
  }
}

.form-group_add__document__driver {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;

  label {
    color: #344054;
    @include font('Inter', 500, 14px, 20px);
  }

  select {
    color: #101828;
    @include font('Inter', 600, 15px, normal);
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    padding: 13px 14px 12px 14px;
  }
}

.form-buttons_add__document__driver {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .add__document-btn-cancel {
    border-radius: 10px;
    background: #FFF;
    border: none;
    padding: 13px 134px 12px 133px;
    cursor: pointer;
    @include font('Inter', 500, 16px, normal);
    transition: ease-in 0.3s;

    &:hover {
      background: #BBB;
    }
  }

  .add__document-btn-add {
    @include font('Inter', 600, 15px, 24px);
    border: none;
    border-radius: 12px;
    background: #111;
    color: #FFF;
    cursor: pointer;
    padding: 10px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    transition: ease-in 0.3s;

    &:hover {
      background: #0E91AF;
    }
    &:disabled {
      background: #111;
    }
  }
}

.add__document_file-input-container {
  input {
    @include font('Inter', 600, 15px, 20px);
    position: absolute;
    width: 100%;

  }

  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 44px

}

.document-upload-text {
  display: flex;
  justify-content: center;
  align-items: center;
  @include font('Inter', 600, 15px, 20px);
  background-color: #989898 !important;
  color: white !important;
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

.file-input-add__document {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.modal_add_driver__close {
  cursor: pointer;
}