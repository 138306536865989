@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modal__add__customerBlackList {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content__add__customerBlackList {
    width: 100%;
    max-width: 462px;
    border-radius: 12px;
    background: #F4F4F4;
    padding: 16px;

    .heading__modal__customerBlackList {
      display: flex;
      justify-content: space-between;
      @include font(Inter, 600, 16px, 24px);
      margin-bottom: 14px;

      .close__modal__customerBlackList {
        cursor: pointer;
      }

      .title__modal__customerBlackList {

      }
    }

    .btnContainerAddBlackList {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 25px;
    }

    .bodyAddBlackListModal {

      .reasonContainerAddBlackList {
        @include font(Inter, 500, 14px, 24px);
        label{
          display: flex;
          justify-content: space-between;
          @include font(Inter, 500, 14px, 24px);
        }
      }

      #reasonTextArea {
        resize: none;
        padding: 14px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        width: 100%;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        @include font(Inter, 400, 13px, 24px);
        height: 109px;
      }

      .btnAddBlackList {
        cursor: pointer;
        border-radius: 12px;
        background: #111;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        padding: 16px;
        @include font(Inter, 600, 15px, 24px);
        transition: ease-in 0.3s;

        &:hover {
          background: #0E91AF;
        }
      }

      .btnCancellackList {
        cursor: pointer;
        color: #111111;
        border-radius: 10px;
        background: #FFF;
        @include font(Inter, 500, 16px, 24px);
        padding: 16px;
        display: flex;
        justify-content: center;
        transition: ease-in 0.3s;

        &:hover {
          background: #BBB;
        }
      }
    }

  }
}


