@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.alert__message {
  display: flex; 
  background-color: #FFF;
  padding: 10px 10px 10px 10px;
  margin-top: 5px;
  align-content: center;
  margin-bottom: 10px;
  @include font(Inter, 500, 14px, 24px);
  line-height: normal;
  border-radius: 8px;

  svg {
    width: 20px;
    height: 18px;


  }

  span {
    color: #F40000;
    
  }
  p{
    display: flex;
  }
}

.tools__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.one__truck__rightright__item {
  display: flex;
  align-items: center;
  gap: 25px;

  svg {
    width: 20px;
    height: 20px;
  }
}



.icons__dwlnd__trsh {
  display: flex;
  gap: 10px;

  svg {
    color: #B1B1B1;

  }
}

.documents__table__body__item {

  white-space: nowrap;
}

$BG-BACK-COLOR: #EFF0F2;
$BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
$COLOR-BLACK-DIMMED: #454545;


.documents__title {
  @include font(Inter, 700, 25px, normal);
}


.documents__sort {
  display: grid;
}

.documents__right__item {
  display: flex;
  gap: 15px;
}


.documents__table__title {

  justify-content: space-between;
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
  padding: 0px 50px 0px 20px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


.documents__table__row {
  display: flex;
  justify-content: space-between;
  padding: 15px 50px 15px 20px;
  border-bottom: 0.6px solid #C9C9C9;
  background: #FFF;
  
  transition: box-shadow 0.3s ease;
}

.documents__table__row:hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.documents__table__row:not(:hover) {
  opacity: 0.7;
  z-index: 1;
}

.documents__table__title__item {
  width: 0%;
  padding: 8px;
  white-space: nowrap;
  @include font(Inter, 600, 14px, 21px);
  display: flex;
  align-items: center;
}

.documents__table__body__item {
  @include font(Inter, 500, 15px, 21px);

  &:hover {
    @include font(Inter, 500, 15px, 21px);
  }
}

.documents__table {
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
}

.documents__sort {
  display: grid;
}


.documents__table__body__item {
  width: 1%;
  padding: 8px;
}

.title__modal__document {
  @include font(Inter, 600, 16px, normal);
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 890px) {
  .documents__table {
    width: 200%;
  }
}

@media screen and (max-width: 460px) {
  .documents__table {
    width: 300%;
  }
}

@media screen and (max-width: 979px) {
  .documents__choice {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 554px) {
  .documents__right__item {
    display: grid;
  }
  .documents__panel {
    display: block;
  }
}


$BG-BACK-COLOR: #EFF0F2;
$BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
$COLOR-BLACK-DIMMED: #454545;
$SPAN-COLOR-GREY: #737F8F;
$BORDER-COLOR-LIGHT-GREY: #DBDBDB;

.one__truck__page {
  display: flex;
  width: 100%;
  background-color: $BG-BACK-COLOR;
}


.one__truck__container {
  padding: 45px 38px 39px 38px;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow-y: auto;
}

.one__truck__title {
  @include font(Inter, 700, 25px, normal);
  align-items: center;
  margin-bottom: 10px;
  display: flex;

  button {
    border-color: #0E91AF;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 20px;
    background-color: #FFF;
    border: solid 2px #0E91AF;
    transition: all 0.2s;


  }

  svg {

    color: #0E91AF;
    width: 20px;
    height: 20px;
    padding-top: 2px;
  }
}

.one__truck__title {
  button:hover {
    background-color: #0E91AF;

    svg {
      color: #FFF;
    }
  }


}


.one__truck__panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
}

.one__truck__choice {
  display: flex;
}

.one__truck__right__item {
  display: flex;
  gap: 15px;
}

.one__truck___register_date {
  display: flex;
  align-items: center;

}


.one__truck__filter {
  @include font(Inter, 400, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;


  svg {
    height: 20px;
    width: 20px;
  }

  p {
    color: $COLOR-BLACK-DIMMED;
  }
}


.one__truck__search {
  @include font(Inter, 400, 14px, 24px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding: 5px 14px;
  outline: 0;

  input {
    border: none;
    outline: none;
    width: 240px;
    @include font(Inter, 400, 15px, 21px);
  }
}

.one__truck__search > svg:nth-child(1) {
  height: 30px;
  width: 20px;
}

.one__truck__table__title {
  display: flex;
  justify-content: space-between;
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
  padding: 15px 50px 15px 20px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


.one__truck__table__row {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-bottom: 0.6px solid #C9C9C9;
  background: #FFF;
  // cursor: pointer;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  }
}

.one__truck__img__description {
  display: flex;
  width: 100%;
}

/* Оверрайд стандартних стилів кружечків у React Slick */
.slick-dots li button:before {
  font-size: 10px;
}

.one__truck__table__body__item__img {
  width: 100%;
  max-width: 317px;
  object-fit: cover;

  img {
    width: 315px;
  }
}

.name__description {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.name__detailed__description {
  margin-left: 15px;
}


.one__truck__table__title__item {
  @include font(Inter, 600, 14px, 21px);
}

.one__truck__table__body__item {
  margin-right: 30px;
  line-height: 1.69;
  @include font(Inter, 500, 15px, 21px);

  &:hover {
    @include font(Inter, 500, 15px, 21px);
  }
}

.one__truck__table__body__item.upper {
  margin-bottom: 15px;
  line-height: 30px;
  border-left: solid;
  border-width: 1px;
  border-color: $BORDER-COLOR-LIGHT-GREY;
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.drivers__search__component {
  select, option {
    @include font(Inter, 500, 15px, 21px);
    color: $SPAN-COLOR-GREY;
  }

}

.one__truck__table__body__item.lower {
  border-left: solid;
  border-width: 1px;
  border-color: $BORDER-COLOR-LIGHT-GREY;
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.one__truck__table__body__item.name {
  font-weight: 700;
  font-size: 17px;
}


@media screen and (max-width: 1024px) {
  .one__truck__page {
    flex-direction: column;
    height: 100svh;
  }
}

.documents__table__body__item.actions__document {

  svg {
    margin-right: 10px;
  }

}

@media screen and (max-width: 1471px) and (min-width: 1144px) {
  .one__truck__img__description {
    display: block;
  }
  .one__truck__table__row {

    justify-content: center;

  }
  .one__truck__table__body__item__img {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1143px) {
  .one__truck__img__description {
    display: block;
  }

  .ID__license {
    display: block;
  }

}

@media screen and (max-width: 916px) {
  .tools__panel {
    display: block;

  }
  .one__truck__right__item {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .one__truck__rightright__item {

    justify-content: center;
  }

}

@media screen and (max-width: 682px) {
  .one__truck__right__item {
    display: block;
    justify-content: unset;

    .drivers__search__component {
      margin-bottom: 10px;
    }

    .one__truck__search {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 436px) {
  .one__truck__table__body__item__img {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.one__truck__title {
  button {
    cursor: pointer;
  }
}

.table__sort__docs {
  display: flex;
  align-items: center;
}

.table__sort__icons {
  display: flex;
  flex-direction: column; // Stack children vertically
}


.table__wrapper__docs {

  margin-inline: auto;

  table {
    border-collapse: collapse;
    background-color: $BG-BACK-COLOR-TABLE-TITLE;
    border-radius: 10px;
    width: 100%;

    th,
    td {
      padding: 10px 5px 10px 20px;
      width: 26%;
      @include font(Inter, 500, 15px, 21px);
      transition: all 0.1s;
    }

    th {
      text-align: left;
      padding: 15px 50px 15px 20px;
      @include font(Inter, 600, 14px, 21px);

      border-radius: 10px;
    }

    td {
      border-bottom: 0.6px solid #C9C9C9;
      background: #FFF;
      
      padding: 25px 50px 25px 20px;

    }

    tr {
      transition: all 0.2s;

    }
tbody{
    tr:hover {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
      z-index: 2;
    }
  }

    .table__tools__docs {
      svg{
        cursor: pointer;
      }
      text-align: end;
    }

    thead {
      background-color: $BG-BACK-COLOR-TABLE-TITLE;
      padding: 20px 50px 20px 20px;

      border-radius: 10px;
    }

    .table__sort__docs {
      cursor: pointer;

      svg {
        width: 30px;
        height: 20px;

      }

      .table__sort__less {
        height: 15px;
      }

      .table__sort__more {
        height: 20px;
      }
    }
  }

  tbody:hover > :not(:hover) {
    opacity: 0.5;

  }

  .table__container__docs {
    overflow-x: auto;
    max-width: 100%;
  }
}
.one__truck__table__row{
.swiper-slide.fl__slider.swiper-slide-active{
  img{
    object-fit: cover !important;
    height: 100%;
    border-radius: 8px;
    width: 100%;
  }
} 
}

.modal-content__edit__track{
  position: absolute;
  right: 0;
  background: #fff;
  padding: 11px;
    padding-right: 11px;
  border-radius: 8px;
  padding-right: 20px;
  z-index: 10000;
  gap: 5px;
  width: 179px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 16px;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  line-height: 1.5em;
}

.truck__name__year{
  display: flex;
}

 .d__menu__item__track{
cursor: pointer;
transition: all 0.2s ease-in-out;
 }
 .d__menu__item__track:hover{
  color: #0E91AF;
 }