
@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$COLOR-BLACK-DIMMED:#344054;
$COLOR-INPUT:#101828;

.title__perfonal__info{
  display: flex;
  justify-content: space-between;
}

.personal__title{
  @include font('Inter', 600, 20px, 24px);
}

.edit__personal__info{
  cursor: pointer;
}
.form__group__profile {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  label {

      @include font('Inter', 500, 14px, normal);
    line-height: 20px; 
    color: $COLOR-BLACK-DIMMED;
    
  }

  input {
@include font('Inter', 600, 16px, normal);
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    padding: 15px 14px 14px 14px;
    background:  #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $COLOR-INPUT;
  }
}

.form__group__edit__password{

  label{
    color: $COLOR-BLACK-DIMMED;
  }

  input{
    color: $COLOR-INPUT;
  }
}

