@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.note__container {
    display: flex;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    margin-left: 20px;
    gap: 76px;
    margin-right: 20px;
    justify-content: space-between;
  
    textarea {
      height: 125px;
        width: 300px;
        background-color: #F1F5F5;
        border-radius: 8px;
        border-style: none;
        resize: none;
        @include font(Inter, 400, 14px, 20px);
        padding: 10px;
      }
      textarea:focus {
        outline: none;
      }
      
  }

  .note__name {
@include font(Inter, 600, 16px, 24px);
display: flex;
gap: 10px;
align-items: center;
margin-bottom: 10px;
svg{
  width: 17px;
  height: 17px;
  color: #0E91AF;
}
  }
  .driver__summary {
    
    display: flex;
    flex-direction: column;
gap: 12px;
@include font(Inter, 500, 16px, 21px);
span{
  color: #0E91AF; 
}
.driver__miles{
  padding-left: 18px;
}
  }
  .driver__summary > div:nth-child(1) {
    @include font(Inter, 600, 16px, 21px);
  }

  .note__driver__name{
    
    @include font(Inter, 800, 18px, 24px);
    margin-bottom: 19px;
  }

  .note__driver__info {
    margin-right: 150px;
}

.data__not__found{
  @include font(Inter, 600, 18px, 21px);
  text-align: center;
  margin-top: 220px;
  

}

.loader__main{
  position: initial;
top: 200px;


.loader{
  img{
    position: relative;
    top: 0px;
    left: 140%;
  }
}
}

.load__container {
  margin: 20px 20px; 
}

.load__tools__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.load__title__buttons {
  button:hover{
    background-color: #0E91AF;

  }
  align-items: center;
  @include font(Inter, 600, 16px, 24px);
  display: flex;
  gap: 10px;
  button{
    cursor: pointer;
    padding: 2px 12px;
    border-style: none;
    border-radius: 8px;
    background-color: #111111;
    color: white;
    @include font(Inter, 500, 14px, 24px);
    align-items: center;
  display: flex;
  transition: all 0.1s ease;
  
  svg{
    margin-left: 8px;
    width: 13px;
    height: 13px;
  }
}
  
}

.no__load__logo{
  color: #313131;
  @include font(Inter, 500, 16px, 21px);
  text-align: center;
  margin-top: 10%;
}


.loads__search__component {
  @include font(Inter, 400, 14px, 24px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding: 5px 14px;
  outline: 0;
  height: 28px;
  

  input {
    border: none;
    outline: none;
    width: 200px;
    @include font(Inter, 400, 15px, 21px);
  }

  select {
    border: none;
    background-color: #FFF;

  }
}
.load-select{
  width: 120px !important;
}
.load-options-list{
  width: 145px !important;
}
.load-selected-option{
  svg{
    width: 20px;
    height: 20px;
    color: #64748B;
  }
}