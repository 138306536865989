@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$BG-BACK-COLOR: #EFF0F2;
$BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
$COLOR-BLACK-DIMMED: #454545;
$SPAN-COLOR-GREY: #737F8F;
$BORDER-COLOR-LIGHT-GREY: #DBDBDB;

.trucks__page {
  display: flex;
  width: 100%;
  background-color: $BG-BACK-COLOR;
}


.trucks__container {
  padding: 45px 38px 39px 38px;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow-y: auto;

}

.trucks__title {
  @include font(Inter, 700, 25px, normal);
}

.c__n {
  display: flex;
  gap: 20px;
}

.trucks__sort {
  display: grid;
}

.trucks__panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
}

.trucks__choice {
  display: flex;
  margin-bottom: 10px;
}

.trucks__right__item {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

.trucks___register_date {
  display: flex;
  align-items: center;

}

.trucks__sort > svg:nth-child(1) {
  width: 30px;
  height: 20px;
}

.trucks__sort > svg:nth-child(2) {
  width: 30px;
  height: 20px;
}

.trucks__ button {
  @include font(Inter, 600, 18px, normal);
  padding: 10px 64px;
  border-radius: 8px;
  border: none;
}

.trailers__ button {
  @include font(Inter, 600, 18px, normal);
  padding: 10px 64px;
  border: none;
  border-radius: 8px;
  background: #EFF0F2;
}

.trucks__add {
  @include font(Inter, 400, 14px, 20px);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--White, #FFF);
  cursor: pointer;
}

.trucks__filter {
  @include font(Inter, 400, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;


  svg {
    height: 20px;
    width: 20px;
  }

  p {
    color: $COLOR-BLACK-DIMMED;
  }
}

.alert {
  margin-top: 5px;
  align-content: center;
  display: flex;
  color: #F40000;
  gap: 10px;
  @include font(Inter, 500, 14px, 24px);
  line-height: normal;
  display: flex;
  flex-direction: column;
  svg {
    width: 20px;
    height: 18px;
  }
}

.warning {
  margin-top: 5px;
  align-content: center;
  display: flex;
  gap: 10px;
  color: #F89400;
  text-align: center;
  @include font(Inter, 500, 14px, 24px);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 20px;
    height: 18px;
  }
}

.trucks__search {
  @include font(Inter, 400, 14px, 24px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding: 5px 14px;
  outline: 0;

  input {
    border: none;
    outline: none;
    width: 240px;
    @include font(Inter, 400, 15px, 21px);
  }
}

.trucks__search > svg:nth-child(1) {
  height: 30px;
  width: 20px;
}

.trucks__table__title {
  display: flex;
  justify-content: space-between;
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
  padding: 15px 50px 15px 20px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


.trucks__table__row {
  display: flex;
  justify-content: center;
  padding: 15px 20px 15px 20px;
  background: #FFF;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out; 

  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  }
}

.truck__img__description {
  display: flex;
  max-width: 100%;
}


.trucks__table__body__item__img {
  img {
    width: 315px;
    height: 215px;
    object-fit: cover;
    border-radius: 8px;
  }
}

.name__description {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.name__detailed__description {
  margin-left: 15px;
  max-width: 100%;
  width: 10000px;
}

.ID__license {
  display: flex;
  gap: 14px;

  span {
    display: block ruby;
    color: $SPAN-COLOR-GREY;
    @include font(Inter, 400, 15px, 21px);
  }
}

.trucks__table__title__item {
  @include font(Inter, 600, 14px, 21px);
}

.trucks__table__body__item {
  margin-right: 30px;
  line-height: 1.69;
  @include font(Inter, 500, 15px, 21px);

  &:hover {
    @include font(Inter, 500, 15px, 21px);
  }
}

.trucks__table__body__item.upper {
  margin-bottom: 15px;
  line-height: 30px;
  border-left: solid;
  border-width: 1px;
  border-color: $BORDER-COLOR-LIGHT-GREY;
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.trucks__table__body__item.lower {
  border-left: solid;
  border-width: 1px;
  border-color: $BORDER-COLOR-LIGHT-GREY;
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%
}

.trucks__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.trucks__table__body__item.name {
  font-weight: 700;
  font-size: 17px;
}


.per__page {
  display: flex;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @include font(Inter, 600, 15px, 24px);
  border-radius: 4px;
  background: var(--Neutral-N0, #FFF);
}

.modal__add__truck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content__add__truck {
  background: #F4F4F4;
  padding: 14px 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 735px;
  max-height: 810px;
}

.title__modal__truck {
  @include font(Inter, 600, 16px, normal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__group__cancel__truck__btn {
  margin-top: 13px;

  button {
    width: 100%;
    padding: 14px 20px;
    background: #FFF;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    @include font(Inter, 600, 15px, normal);
    transition: all 0.2s ease-in-out;
  }
  button:hover{
    background-color: #DBDBDB;
  }
}


.close__modal__truck {
  cursor: pointer;

  svg {
    font-size: 30px;
  }
}

.pagination__pages {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 15px;
}



.pagination__pages > input {
  height: 100%;
  width: 13%;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 15px;
}

@media screen and (max-width: 1488px) {
  .truck__img__description {
    display: grid;
  }
  .name__detailed__description {
    width: 99%;
  }
  .trucks__table__body__item__img {
    display: grid;
    justify-items: center;
  }

}

@media screen and (max-width: 1144px) {
  .trucks__table__body__item__img img {
    margin-bottom: 10px;
  }

}

@media screen and (max-width: 1024px) {
  .trucks__page {
    flex-direction: column;
    height: 100svh;
  }
}

@media screen and (max-width: 737px) {
  .left__right__columns {
    display: grid !important;
  }
  .modal__content__edit__track form {
    display: grid;
    justify-content: center;
  }
}

@media screen and (max-width: 617px) {
  .trucks__right__item {
    display: grid;
    width: 100%;
  }
}

@media screen and (max-width: 469px) {
  .trucks__table__body__item__img img {
    width: 100%;
  }
}

@media screen and (max-width: 454px) {
  .trucks__choice {
    display: grid;
    margin-bottom: 10px;
    width: 100%;
  }
  .trailers__ button {
    width: 100%;
  }
  .customers__approved.active {
    width: 100%;
  }
  .trucks__ button {

    width: 100%;
  }

}

.trucks__approved {
  @include font(Inter, 600, 18px, normal);
  padding: 6px 51px;
  border-radius: 8px;
  background: #EFF0F2;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

td {
  width: 20%
}

.trucks__blacklist {
  @include font(Inter, 600, 18px, normal);
  padding: 6px 51px;
  border: none;
  background-color: #EFF0F2;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

}

.left__right__columns {
  display: flex;
}