@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;

}

.messageAskReport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.80);
  z-index: 99;

  &.open {
    display: flex;
  }

  .modalContentAskReport {
    width: 100%;
    max-width: 520px;
    border-radius: 8px;
    background: #F4F4F4;
    padding: 14px 20px;
    z-index: 100;

    .messageContainerAskReport {
      border-radius: 6px;
      background: #FFF;
      padding: 12px 14px;
      color: #0E91AF;
      @include font(Inter, 600, 14px, 24px);
    }

    .headModalAskReport {
      margin-bottom: 20px;
    }

    .headModalAskReport {
      display: flex;
      justify-content: space-between;

      .titleModalAskReport {
        @include font(Inter, 600, 16px, 24px);
      }
    }

    .closeAskReportModal {
      cursor: pointer;
    }
  }
}
