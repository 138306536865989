@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 22px;
  background: #B6BBBD;

}


body {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}


// SELECT
.custom-select {
  border: none;
  position: relative;
  cursor: pointer;
  width: 260px;
  max-width: 100%;
  height: 29px;
}


.selected-options {
  display: flex;
  align-items: center;
  
  border: none;
  cursor: pointer;
  height: 100%;
  width: 100%;

}
.selected-option {

  @include font(Inter, 400, 15px, normal);
  color: #64748B;
  svg{
    width: 20px;
    height: 20px;
  }
}
.selected-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.options-list {
  position: absolute;
  top: 40px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 2;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  width: 287px;
  right: -14px;
  max-height: 200px;
  overflow-y: auto;
}
.option-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
  transition: background-color 0.1s ease;
  svg{
    width: 20px;
    height: 20px;
    color: #0E91AF;
  }
  label{
    cursor: pointer;
  }
  
}
.option-item:hover{
  background-color: #F2F7FA;
}
/* Стилізація для активного option-item */
.custom-select .options-list .option-item.active {
  background-color: #F2F7FA;
}

.option-item .checkbox {
  margin-right: 8px;
}

/* Show options-list when .custom-select is active */
.custom-select.active .options-list {
  display: block;
}

