// EditPasswordModal.scss
$BG-COLOR-GREY: #F4F4F4;
$LABEL-COLOR-LOGIN: #344054;
$INPUT-COLOR-LOGIN: #000;
$INPUT-COLOR-HOVER-LOGIN: #0E91AF;
$CLOSE-COLOR: #111;

@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modal__overlay__edit__password {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.80);
}
.modal__content__edit__password {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 520px;
  max-height: 80%;
  overflow-y: auto;
  background-color: $BG-COLOR-GREY;

  .message__password{

    p{
      background: #FFFFFF;
      padding: 20px;
      @include font('Inter', 600, 14px, 24px);
    }

  }
}

@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}
.btn__group__edit__info{
  display: flex;
  flex-direction: column;
  gap: 14px;

  .btn__save__edit__info{
    border-radius: 8px;
    background: $CLOSE-COLOR;
    color: #fff;
    padding: 12px 97px;
    @include font('Inter', 600, 15px, 24px);
    cursor: pointer;

  }

  .btn__close__edit__info{
    color: #111;
    border-radius: 8px;
    background: #FFF;
    padding: 12px 97px;
    @include font('Inter', 500, 16px, normal);
    cursor: pointer;
  }
}
/* Стилі для затемнення фону */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Стилі для самого модального вікна */
.modal {
  border-radius: 8px;
  background: #F4F4F4;
  padding: 20px;
  width: 90%; /* Ширина модального вікна */
  max-width: 520px; /* Максимальна ширина модального вікна */
  max-height: 80vh; /* Максимальна висота модального вікна */
  overflow-y: auto; /* Додавання вертикальної прокрутки при необхідності */
}


.modal__head__pass__info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include font('Inter', 600, 16px, 24px);
  }

  .close {
    cursor: pointer;
    color: $CLOSE-COLOR;

    svg{
      height: 20px !important;
  width: 20px !important;
    }
  }
}

.btn__group__edit__info{
  button{
    border: none;
  }
}
.password-wrapper {
  position: relative;


  input[type="password"] {
    padding-right: 30px;
  }

  .toggle-icon {
    position: absolute;
    right: 10px;
    top: 58%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.form_group__container {
  .password-wrapper {
    position: relative;


    input[type="password"] {
      padding-right: 30px;
    }

    .toggle-icon {
      position: absolute;
      right: 10px;
      top: 58%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  label {
    color: $LABEL-COLOR-LOGIN;
    @include font(Inter, 500, 14px, 20px);
    display: flex;
    justify-content: space-between;

    .error-message, .error{
      color: #FF3939;
      @include font(Inter, 600, 14px, 24px);
    }
  }


  input {
    margin-top: 6px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: white;
    padding: 12px 16px;
    outline: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @include font(Inter, 400, 16px, 24px);
    color: $INPUT-COLOR-LOGIN;
    cursor: pointer;
    transition: all 0.2s;

  }
  input:hover{
    border-color: #636366;
  }
}
