@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.title__edit__password {
  @include font(Inter, 600, 16px, 24px);
}

.header__edit__password {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.icon__edit__password{
  cursor: pointer;
}

.form__group__edit__password {
  display: flex;
  flex-direction: column;

  label {
    @include font(Inter, 500, 14px, 20px);
  }

  input {
    @include font('Inter', 600, 16px, normal);
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    padding: 15px 14px 14px 14px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}