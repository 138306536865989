@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.AdminMainPage {
  padding: 40px;
  width: 100%;

  .imgContainerAdminMain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
  }

  .titleAdminMainPage {
    width: 100%;
    display: flex;
    @include font(Inter, 600, 18px, 24px);
  }
}