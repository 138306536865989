@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modalOverlayPasswordAdmin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modalPasswordUser {
  border-radius: 10px;
  background: #EFF0F2;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.btnContainerPasswordAdmin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.saveNewPasswordAdm {
  @include font("Inter", 600, 15px, 24px);
  color: #FFFFFF;
  border-radius: 8px;
  padding: 12px;
  background: #111;
  cursor: pointer;
  transition: ease-in 0.2s;

  &:hover {
    background: #0E91AF;
  }
}

.closeBtnChangePasAdmin {
  border-radius: 8px;
  background: #FFF;
  @include font("Inter", 600, 15px, 24px);
  color: #111;
  padding: 12px;
  cursor: pointer;
  transition: ease-in 0.2s;

  &:hover {
    background: #BBB;
  }
}

.inputContainerChangePasAdmin{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  input{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background:#FFF;
    @include font("Inter", 400, 15px, 24px);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 14px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none; // Prevent clicking
}
