@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}
$PHOTO-UPLOAD-COLOR: #989898;
// EditPhotoTruckComponentModal.scss
.modal__overlay__edit__photo__truck {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.5); /* Затемнений фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Надати велике значення zIndex для перекриття елементів позаду */
  pointer-events: auto; /* Блокування взаємодії з елементами за модальним вікном */
}

.modal__content__edit__photo__truck {
  background: #F4F4F4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%; /* Змініть розмір за вашими потребами */
  max-width: 867px;
  margin: 0 auto;
  max-height: 90vh;
  overflow-y: auto;

}

.modal__header__edit__photo__truck {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  svg{
    height: 30px;
    width: 30px;
  }
}

.image-upload-elements {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.upload-square {
  width: 173px;
  height: 117px;
  border-radius: 3px;
  background: #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 8px;

}

.upload-square input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-text {
  color: #fff;
  text-decoration: underline;
  
}
.hidden {
  display: none;
}


.image-preview {
  position: relative;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clear-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 7px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  z-index: 99;
  display: block;
  cursor: pointer;
  svg{
    width: 15px;
    height: 15px;
    
  }
}

.full-preview {
  width: 100%;
  max-width: 617px;
  max-height: calc(100vh - 200px); /* Зміна висоти для пристосування до вікна */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.full-preview-image {
  max-width: 100%;
  max-height: calc(100vh - 250px); /* Зміна висоти для пристосування до вікна */
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 8px;
}

.close-full-preview {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.modal__btn__container__edit__photo__truck {
  display: flex;
  flex-direction: column;
  gap: 14px; 
  margin-top: 80px;

  .save__photo__truck__btn {
    width: 100%;
    border-radius: 8px;
    background: #111;
    color: #fff;
    padding: 12px 97px;
    border: none;
    cursor: pointer;
    @include font(Inter, 600, 15px, 24px);
    transition: all 0.2s ease-in-out;
    &:hover {
 background-color: #0E91AF;
    } 
  }


  .cancel__photo__truck__btn {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    background: #FFF;
    border: none;
    color: #111;;
    padding: 15px 213px 14px 213px;
    @include font(Inter, 500, 16px, normal);
    transition: all 0.2s  ease-in-out;
    &:hover {
      background-color: #BBBBBB;
    }
  }
}

.title__choose__truck{
  margin: 10px;
}

.file-input-label{
  background-color: $PHOTO-UPLOAD-COLOR;
  width: 173px;
  height: 117px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  input{
    display: none;
  }
}
.selected-preview-image{
  width: 173px;
  height: 117px;
  border-radius: 8px;
  position: absolute;
  object-fit: cover;
}


.show-clear-button {
  display: block; /* Виводити кнопку, якщо вона має цей клас */
}

.preview__body {
  display: flex;
  justify-content: center;

  &.with-full-preview {
    height: 400px;
    border-radius: 8px;
  }
}
.modal__content__edit__photo__truck{
  h3{
    color: #000;

@include font(Inter, 600, 16px, 24px);
  }
  span{
    color: #ffffff;

@include font(Inter, 500, 14px, 24px);
  }
}

.modal__close__edit__photo__truck{
  cursor: pointer;
}