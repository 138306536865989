/* EditPasswordSuccessModal.scss */

.modal__overlay__success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal__content__success {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal__head__success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title__success {
  font-size: 1.5rem;
  font-weight: bold;
}

.close__success {
  cursor: pointer;
}

.message__success {
  margin-top: 20px;
}

.btn__close__success {
  background: #ddd;
  color: #333;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}

.btn__close__success:hover {
  background: #ccc;
}

.btn__close__success:focus {
  outline: none;
}

