@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }

.load__body__content{
    @include font(Inter, 500, 14px, 20px);
    
}
.load__body__content.b{
color: #FF3939;
}
.form__group__delete__load__btn{
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-top: 20px;
}
.delete__load__btn{
display: flex;
    align-items: center;
    cursor: pointer;
    @include font(Inter, 600, 15px, 24px);
    color: #FFF;
    border-radius: 12px;
    background: #111;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
height: 44px;
padding: 10px 60px;
justify-content: center;
    transition: ease-in 0.2s;

    &:hover {
      background: #0E91AF;
    }
}
.cancel__delete__load__btn{
    border-radius: 12px;
    background: #FFF;
    border: none;
    height: 44px;
    padding: 10px 60px;
    cursor: pointer;
    @include font('Inter', 600, 15px, 24px);
    transition: ease-in 0.2s;

    &:hover {
      background: #BBB;
    }
}