@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
}

.status__driver__container {
    display: flex;
    justify-content: space-between;
    background-color: black;
    }

    .status__driver{
        @include font(inter, 600, 15px, 24px);
        
    }
    .status__driver{
        background-color: white;
        color: black;
        border-style: none;
        border-radius: 15px;
        padding: 2px 15px;
        cursor: pointer;
        transition: all 0.2s ease;

    }

    @media screen and (max-width: 1024px) {
        .status__driver{
            padding: 2px 6px;
        }
    }

    .status__driver.active{
        color: white;
       
    }
    .status__driver__container{

  bottom: 0;
  width: 100%; 
  align-items: center;
  padding: 15px;
    }

    .activestatus{
        background-color: #0E91AF;
    }