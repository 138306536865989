@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;

}
.ddddggg{
  height: 100%;

  svg{
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.dropdownOptionsTrailer{
    display: flex;
  gap: 14px;
    .DropItemsTrailer{
      padding: 14px;
      border-radius: 8px;
      background: #FFF;
      width: 100%;
      max-width: 300px;

    }

  .DropItemsTrailer__type{
    margin-bottom: 40px;
  }
}
.DropItemsTrailer__type{
  color: #344054;
  @include font(Inter, 500, 14px, 20px);
  span{
    color: #0E91AF;
  }
}
.DropItemsTrailer__list__container,
.DropItemsTrailer{
  p{
    color: #344054;
    @include font(Inter, 400, 14px, 20px);
  }
  ul,
  li{
    @include font(Inter, 600, 16px, 20px);
    color: #101828;
  }
  ul{
    width: 100%;
  }
}

.DropItemsTrailer__elem{
  margin-left: 24px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.typeTitleTrailer{
  @include font(Inter, 600, 18px, 24px);
  margin: 5px 0px;
}

/* Додайте новий стиль для модального вікна */
.admin__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Прозорий чорний фон */
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}


$green: #0E91AF;
$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
.DropItemsTrailer__items{
  flex-direction: column;
  
}

.delete__cross{
  position: relative;
  top: 2px;
  cursor: pointer;
}

.modal__text{
  @include font(Inter, 600, 16px, 20px);
}

.modal__confirm{
  @include font(Inter, 600, 14px, 20px);
  justify-content: center;
  display: flex;
  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 10px 5px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.modal__confirm:hover{
  background-color: #0E91AF;
}

.modal__second__text{
  span{
    @include font(Inter, 500, 14px, 20px);
  }
}

.modal__title{
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.DropItemsTrailer__list__c{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}