@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }
  
  $BG-BACK-COLOR: #EFF0F2;
  $BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
  $COLOR-BLACK-DIMMED: #454545;
  
  .help__page {
    display: flex;
    width: 100%;
    background-color: $BG-BACK-COLOR;
  }
  
  
  .help__container {
    padding: 45px 38px 39px 38px;
    width: 100%;
    min-height: 100%;
    height: 100vh;
    overflow-x: auto;
  }
  
  .help__title {
    @include font(Inter, 700, 25px, normal);
  }
  
  .c__n {
    display: flex;
    gap: 20px;
  }
  
  .help__sort {
    display: grid;
  }
  
  .help__panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
  }
  
  .help__choice {
    display: flex;
    margin-bottom: 10px;
  }
  
  .help__right__item {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    z-index: 9;
  }
  
  .help___register_date {
    display: flex;
    align-items: center;
  
  }
  
  .help__sort > svg:nth-child(1) {
    width: 30px;
    height: 20px;
  }
  
  .help__sort > svg:nth-child(2) {
    width: 30px;
    height: 20px;
  }
  
  .help__approved {
    @include font(Inter, 600, 18px, normal);
    padding: 6px 51px;
    border-radius: 8px;
    background: #EFF0F2;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  td {
    width: 20%
  }
  
  .help__blacklist { 
    @include font(Inter, 600, 18px, normal);
    padding: 6px 51px;
    border: none;
    background-color: #EFF0F2;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
  
  }
  
  .help__calendar {
    @include font(Inter, 400, 14px, 20px);
    display: flex;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--White, #FFF);
    cursor: pointer;
    svg{
        width: 20px;
    height: 20px;
    }
  }
  
  .help__filter {
    @include font(Inter, 400, 14px, 20px);
    border-radius: 8px;
    background: var(--White, #FFF);
    display: flex;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  
  
    svg {
      height: 20px;
      width: 20px;
    }
  
    p {
      color: $COLOR-BLACK-DIMMED;
    }
  }
  
  .help__search {
    @include font(Inter, 400, 14px, 24px);
    border-radius: 8px;
    background: var(--White, #FFF);
    display: flex;
    align-items: center;
    padding: 5px 14px;
    outline: 0;
  
    input {
      border: none;
      outline: none;
      width: 240px;
      @include font(Inter, 400, 15px, 21px);
    }
  }
  
  .help__search > svg:nth-child(1) {
    height: 30px;
    width: 20px;
  }
  
  .help__table__title {
  
    justify-content: space-between;
    background-color: $BG-BACK-COLOR-TABLE-TITLE;
    padding: 0px 50px 0px 20px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .email__num {
    display: flex;
    justify-content: space-between;
    gap: 130px;
  }
  
  .email__num__header {
    display: flex;
    justify-content: space-between;
    gap: 111px;
  }
  
  .help__table__row {
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 15px 20px;
    border-bottom: 0.6px solid #C9C9C9;
    background: #FFF;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }
  
  .help__table__row:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
  }
  
  .help__table__row:not(:hover) {
    opacity: 0.7;
    z-index: 1;
  }
  
  .help__approved.active {
    background-color: white; /* Колір для кнопки Approved у стані active */
  
  }
  
  .help__blacklist.active {
    background-color: white; /* Колір для кнопки Blacklist у стані active */
  
  }
  
  .help__table__title__item {
    width: 0%;
    padding: 8px;
    white-space: nowrap;
    @include font(Inter, 600, 14px, 21px);
    display: flex;
    align-items: center;
  }
  
  .help__table__body__item {
    @include font(Inter, 500, 15px, 21px);
  
    &:hover {
      @include font(Inter, 500, 15px, 21px);
    }
  }
  
  .help__table {
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
  }
  
  .help__sort {
    display: grid;
  }
  
  .c__n {
    display: flex;
    justify-content: space-between;
  }
  
  .actions__customer{
    position: relative;
  }
  
  .help__table__body__item {
    width: 1%;
    padding: 8px;

  }
  
  .help__pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  

  
  .per__page {
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @include font(Inter, 600, 15px, 24px);
    border-radius: 4px;
    background: var(--Neutral-N0, #FFF);
    border: none;
  }
  
  .pagination {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 10px;
  }
  
  .modal__add__customer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content__add__customer {
    background: #F4F4F4;
    padding: 14px 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 370px;
  }
  
  .title__modal__customer {
    @include font(Inter, 600, 16px, normal);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form__group__cancel__customer__btn {
    margin-top: 13px;
  
    button {
      width: 100%;
      padding: 14px 20px;
      background: #FFF;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    } 
  }
  
  .heading__modal__customer {
    margin-bottom: 20px;
  }
  
  .close__modal__customer {
    cursor: pointer;
  
    svg {
      font-size: 30px;
    }
  }
  
  
  
  

  
  .pagination__pages > span {
  
    border: none;
    border-radius: 5px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 15px;
    background-color: white;
    padding: 10px;
  }
  
  
  .more-actions-modal-help {
    position: relative; /* Відмова від фіксованої позиції */
    width: 4%;
    z-index: 9999;
  
    .more-actions-content-help {
      position: absolute;
      background: #fff;
      padding: 11px;
      border-radius: 8px;
      padding-right: 20px;
      z-index: 10000;
      gap: 5px;
      width: 25vh;
      right: -60px;
    //   left: -20px;
      @include font(Inter, 500, 16px, normal);
      display: inline-flex;
  
      flex-direction: column;
      align-items: flex-start;
  
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
      text-align: initial;
      width: 130px;
      top: -25px;
  
      /* Ваші стилі для вмісту модального вікна */
    }
  
    .more-action-item-help {
      cursor: pointer;
      transition: all 0.1s ease-in-out;
    }
    .more-action-item-help:hover {
      color: #0E91AF;
    }
  }
  
  
  @media screen and (max-width: 1024px) {
    .help__page {
      flex-direction: column;
      height: 100svh;
    }
  }
  
  @media screen and (max-width: 890px) {
    .help__table {
      width: 200%;
    }
  }
  
  @media screen and (max-width: 460px) {
    .help__table {
      width: 300%;
    }
  }
  
  @media screen and (max-width: 979px) {
    .help__choice {
      margin-bottom: 10px;
    }
  }
  
  @media screen and (max-width: 554px) {
    .help__right__item {
      display: grid;
    }
    .help__panel {
      display: block;
    }
  }
  
  @media screen and (max-width: 392px) {
    .help__choice{
      display: grid;
    }
  }
  
  .table__wrapper__help{
  
  margin-inline: auto;
  
  table{
  border-collapse: collapse;
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
    border-radius: 10px;
    width: 100%;
    th,
    td{
      padding: 10px 5px 10px 40px;
//   width: 15%;
  @include font(Inter, 500, 15px, 21px);
  
    }
    th{
      text-align: left;
      padding: 15px 50px 15px 20px;
      @include font(Inter, 600, 14px, 21px);
    
    border-radius: 10px;
    }
    td{
    border-bottom: 0.6px solid #C9C9C9;
    background: #FFF;
    cursor: pointer;
    padding: 25px 50px 25px 20px;
    
    }
    tr{
      vertical-align: baseline;
      
    }
    tbody{
    tr:hover{
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    z-index: 0;
    td{
      
    }
    }
    
    }
    .table__tools__help{
      text-align: end;
      svg{
        width: 20px;
        height: 20px;
      }
    }
    thead{
    background-color: $BG-BACK-COLOR-TABLE-TITLE;
    padding: 20px 50px 20px 20px;
    
    border-radius: 10px;
    }
    .table__sort__help{
      cursor: pointer;
      svg{
        width: 30px;
        height: 20px;
  
      }
      .table__sort__less{
        height: 15px;
      }
      .table__sort__more{
        height: 20px;
      }
    }
  }
  tbody:hover > :not(:hover){
    opacity: 0.7;
  
    
  }
  .table__container__help{
    // overflow-x: auto;
    max-width: 100%;
  }
  }
  
  .opacity__less__more {
    opacity: 0.5;
  }
  
  .report__img__cont{
    margin-top: 8px;
    display: flex;
    gap: 10px;
align-items: center;
    img{
    width: 40px;
    height: 40px;
    object-fit: cover;
border-radius: 4px;
opacity: 0.5;
transition: opacity 0.2s ease-in-out;
    }
    img:hover{
      opacity: 1;
    }
  }

  .table__container__help{
    p{
      border-width: 1px;
border-style: solid;
text-align: center;
width: 120px;
display: flex;
justify-content: center;
border-radius: 20px;

    }

  }

  .report__status__header{
    position: relative;
    left: 40px;
  }

  .rdrDateDisplayWrapper {

    display: none !important;
  }

  .rdrDefinedRangesWrapper {

    display: none !important;
  }

  .date-range-picker-modal{
    position: absolute;
    right: 360px;
top: 110px;

  }


  .backdrop{
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* Затемнений колір */
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .date-range__body{
display: flex;
flex-direction: column;
  background-color: white;
  padding: 0px 10px 10px;
  border-radius: 8px;
}


  .date__button__container{
display: flex;
justify-content: space-between;
align-items: center; 
margin: 10px;
  }

  .date__cancel{
    display: flex;
    @include font(Inter, 600, 16px, 24px);
    height: 40px;
padding: 8px 28px; 
background-color: white;
border-style: solid;
border-width: 1px;
border-radius: 4px;
cursor: pointer;
transition: all 0.1s ease-in-out;
  }
  .date__cancel:hover{
    background-color: rgb(248, 248, 248);
  }

  .date__apply{
    display: flex;
    @include font(Inter, 600, 16px, 24px);
    height: 40px;
padding: 8px 28px; 
background-color: #5CC3B6;
border-style: solid;
border-width: 1px;
border-radius: 4px;
color: white;
cursor: pointer;
transition: all 0.1s ease-in-out;

  }

  .date__apply:hover{
    background-color: #57b8ab;
  }

  .rdrStartEdge.rdrEndEdge,
  .rdrInRange{
    color: #F2F7FA !important;
    .rdrDayNumber{
 
    }
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #0E91AF !important;
  }
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span{
    color: white !important;
  }
  .rdrDayStartPreview{
    color: #0E91AF !important;
    
  }
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    color: #0E91AF !important;
    position: absolute;
    top: 6px !important;
    left: 3px !important;
    right: 3px !important;
    bottom: 6px !important;
    pointer-events: none;
    border: 1px solid currentColor !important;
    z-index: 1;
    border-radius: 6px !important;
   
  }
  .rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #0E91AF !important;
  }
  
  .rdrDayToday{
    text-decoration: none !important;
    span{
      text-decoration: none !important;
    }

  }
  .rdrMonthAndYearPickers{
    select
  {
    @include font(Inter, 600, 18px, 24px);
  }
}
.rdrWeekDay{
  @include font(Inter, 500, 14px, 24px);
}
.rdrDayNumber{
  @include font(Inter, 800, 16px, 24px)
}
.rdrStartEdge{
  background-color: #5CC3B6 !important;
  color: #5CC3B6  !important;
}
.rdrEndEdge{
  background-color: #5CC3B6 !important;
}


.rdrMonthPicker{
  select{
  padding: 10px 10px 10px 10px !important;
  }
  select:hover{
    background-color: white !important;
  }
}
.rdrYearPicker{
  select{
    color: #98989D !important;
    padding: 10px 20px 10px 10px !important;
  }
  select:hover{
    background-color: white !important;
  }
}

// .rdrMonthAndYearWrapper {
//   margin: 0px 10px 0px 10px;
//   padding-top: 0px;
// }
// .date-range-picker-modal {
//   position: absolute !important;
//   right: 360px;
//   top: 110px;
//   width: 400px;

// }
// .date-range__body {
//   display: flex;
//   flex-direction: column;
//   background-color: white;
//   padding: 0px 10px 10px;
//   border-radius: 8px;
//   height: 400px;
// }
// .rdrDays {
//   height: 250px;
// }


.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  right: 0px;
}
.rdrEndEdge {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  right: 2px;
}
.rdrStartEdge {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  left: 2px;
}
.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
border-top-left-radius: 6px !important; 
border-bottom-left-radius: 6px !important;
  left: 2px;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {

  margin: 2px !important;
}
.rdrDayToday .rdrDayNumber span{
  font-weight: 600 !important;
}
.rdrDay{
  height: 50px !important;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  border-radius: 6px;
}

.rdrDayNumber span {
  font-weight: 500 !important;
  }
  @media screen and (max-width: 554px) {
    .date-range-picker-modal{
      position: unset;
    }
    .backdrop{
      display: none;
    }
  }