@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.route__name {
  @include font(Inter, 400, 15px, 24px);

  span {
    @include font(Inter, 600, 15px, 24px);
  }
}

.route__name__status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

}

.route {
  position: relative;
margin: 10px 25px;
  background: white;
  /*! width: 432px; */
  padding: 16px 21px;
  border-radius: 8px;
  transition: opacity 0.1s ease-in-out;
  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
}

.route:hover {
  box-shadow: 0px 3px 15px 0px rgb(184, 184, 184);


}

.route:not(:hover):not(.selected) {
  opacity: 0.4;
}

.route.opaque {
  opacity: 1;

}

.route__statuses {
  @include font(Inter, 400, 15px, normal);
  display: flex;
  gap: 10px;

  .route__status {
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    padding: 2px 10px;
  }
}

.route__location {
  @include font(Inter, 600, 15px, 24px);
  align-items: center;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }
}

.routes__container {
  height: 85vh;
  overflow-y: auto;
  border-radius: 8px;

  p {
    @include font(Inter, 600, 15px, 24px);
    text-align: center;
    margin-top: 300px;
  }
}