@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
}

.note__driver__container {
    background-color: white;
    padding: 21px;
    height: fit-content;
        bottom: 100px;
    }
    .note__driver__title {
    margin-bottom: 10px;
    @include font(inter, 600, 15px, 24px);
    color: #939393;
    }

    .textarea__read{
        @include font(inter, 400, 14px, 24px);
    }

.note__driver__wrapper{

    width: 100%;
}