@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}


body{
  overflow: hidden;
}

.help__components__container{
  overflow-y: auto;
  height: 100vh;
}
.help__page__driver{
    display: flex;
}



.modal__add__report__driver {
  display: none;
  position: fixed;
  background-color: rgba(125, 125, 125, 0.70);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.modal-content__add__report__driver{
  background-color: #fefefe;
  padding: 20px;
  border-radius: 8px;
}

.close__add__report__driver {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.show {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .help__page__driver{
      display: block;
  }
}