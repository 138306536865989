@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modalAddUser {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  justify-content: center;
  align-items: center;

  .modal-contentAddUser {
    border-radius: 8px;
    background: #F4F4F4;
    padding: 14px 20px;
    position: relative;
    width: 100%;
    max-width: 520px;
    max-height: 90%;
    overflow-y: auto;
  }

  .HeadAddUserModal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .TitleAddUserModal {
      @include font("Inter", 600, 16px, 24px);
    }

    .CloseAddUserModal {
      cursor: pointer;
    }
  }

  .BodyAddUserModal {

    .formContainerAddUser {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .ErrorMessageAddNewDis{
      display: flex;
      align-items: center;
      gap: 6px;
      color: #FF3939;
      border-radius: 6px;
      background: #FFF;
      padding: 12px 32px 12px 14px;
      @include font("Inter", 600, 14px, 24px);
    }

    .AddNewUserFormGroup {
      display: flex;
      flex-direction: column;

      label {
        display: flex;
        justify-content: space-between;
        @include font("Inter", 500, 14px, 20px);

        .error{
          display: flex;
          align-items: center;
          gap: 6px;
          color: #FF3939;
        @include font("Inter", 400, 13px, 20px);
        }
      }

      input {
        width: 100%;
        @include font("Inter", 600, 16px, 24px);
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 14px;
      }
    }

    .AddNewUserBtn {
      display: flex;
      flex-direction: column;
      gap: 14px;
      cursor: pointer;
      margin-top: 30px;

      .AddNewUserBtnSave {
        display: flex;
        justify-content: center;
        align-items: center;
        @include font("Inter", 600, 15px, 24px);
        color: #FFFFFF;
        border-radius: 12px;
        background: #111;
        padding: 10px 0px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        transition: ease-in 0.5s;
        cursor: pointer;
        border: none;

        &:hover {
          background: #0E91AF;
        }
      }

      .AddNewUserBtnClose {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #111111;
        @include font("Inter", 600, 15px, 24px);
        border-radius: 10px;
        background: #FFF;
        padding: 10px 0px;
        transition: ease-in 0.5s;

        &:hover {
          background: #BBB;
        }
      }
    }
  }

}
