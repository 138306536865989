@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }
  
  $BG-BACK-COLOR: #EFF0F2;
  $BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
  $COLOR-BLACK-DIMMED: #454545;
  $SPAN-COLOR-GREY:#737F8F;
  $BORDER-COLOR-LIGHT-GREY:#DBDBDB;
  
  .trailers__page{
    display: flex;
    width: 100%;
    background-color:$BG-BACK-COLOR;
  }
  
  
  .trailers__container{
    padding: 45px 38px 39px 38px;
    width: 100%;
    min-height: 100%;
    height: 100vh;
    overflow-y: auto;
  }
  
  .trailers__title{
    @include font(Inter, 700, 25px, normal);
  }
  .c__n{
    display: flex;
    gap: 20px;
  }
  .trailers__sort {
    display: grid;
  }
  .trailers__panel{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
  }
  
  .trailers__choice{
    display: flex;
  }
  
  .trailers__right__item{
    display: flex;
    gap: 15px;
  }
  .trailers___register_date {
    display: flex;
    align-items: center;
  
  }
  .trailers__sort > svg:nth-child(1) {
    width: 30px;
    height: 20px;
  }
  .trailers__sort > svg:nth-child(2) {
    width: 30px;
    height: 20px;
  }
  .trailers__ button{
    @include font(Inter, 600, 18px, normal);
    padding: 10px 64px;
    border-radius: 8px;
    background: #FFF;
    border: none;
  }
  
  .trailers__ button{
    @include font(Inter, 600, 18px, normal);
    padding: 10px 64px;
    border: none;
    border-radius: 8px;
    background: #EFF0F2;
  }
  
  .trailers__add{
    @include font(Inter, 400, 14px, 20px);
    display: flex;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--White, #FFF);
    cursor: pointer;
  }
  
  .trailers__filter{
    @include font(Inter, 400, 14px, 20px);
    border-radius: 8px;
    background: var(--White, #FFF);
    display: flex;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  
  
    svg{
      height: 20px;
      width: 20px;
    }
  
    p{
      color: $COLOR-BLACK-DIMMED;
    }
  }

  
  .trailers__search{
    @include font(Inter, 400, 14px, 24px);
    border-radius: 8px;
    background: var(--White, #FFF);
    display: flex;
    align-items: center;
    padding: 5px 14px;
    outline: 0;
  
    input{
      border: none;
      outline: none;
      width: 240px;
      @include font(Inter, 400, 15px, 21px);
    }
  }
  
  .trailers__search > svg:nth-child(1) {
    height: 30px;
    width: 20px;
  }
  
  .trailers__table__title{
    display: flex;
    justify-content: space-between;
    background-color:$BG-BACK-COLOR-TABLE-TITLE ;
    padding: 15px 50px 15px 20px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  
  .trailers__table__row{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    background: #FFF;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    &:hover{
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .trailer__img__description{
    display: flex;
    width: 100%;
  }


.trailers__table__body__item__img{
  img{
  width: 315px;
  height: 215px;

    object-fit: cover;
    border-radius: 8px;
  
  }
}
  
  
  .trailers__table__title__item{
    @include font(Inter, 600, 14px, 21px);
  }
  .trailers__table__body__item{
    margin-right: 30px; 
    line-height: 1.69;
    @include font(Inter, 500, 15px, 21px);
    &:hover{
      @include font(Inter, 500, 15px, 21px);
    }
  }
  .trailers__table__body__item.upper{
    margin-bottom: 15px;
    line-height: 30px;
    border-left: solid;
    border-width: 1px;
    border-color: $BORDER-COLOR-LIGHT-GREY;
    display: flex;
padding-left: 10px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
  }

  .trailers__table__body__item.lower{
    border-left: solid;
    border-width: 1px;
    border-color: $BORDER-COLOR-LIGHT-GREY;
    display: flex;
padding-left: 10px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
  }

  .trailers__pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .trailers__table__body__item.name{
    font-weight: 700;
    font-size: 17px;
  }

  
  .modal__add__trailer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal-content__add__trailer {
    background: #F4F4F4;
    padding: 14px 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 735px; 
    
  }
  
  .title__modal__trailer{
    @include font(Inter, 600, 16px, normal);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form__group__cancel__trailer__btn{
    margin-top: 13px;
    button{
      width: 100%;
      padding: 14px 20px;
      background: #FFF;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      @include font(Inter, 600, 15px, normal);
      transition: all 0.2s ease-in-out;
    }
    button:hover{
      background-color: #DBDBDB;
    }
  }
  
  .heading__modal__trailer{
    margin-bottom: 20px;
  }
  
  .close__modal__trailer{
    cursor: pointer;
    svg{
      font-size: 30px;
    }
  }
  @media screen and (max-width: 1260px) {
    .trailer__img__description {
      display: grid;
    }
    .trailers__table__body__item__img {
      display: grid;
      justify-content: center;
    }
    
  }
  @media screen and (max-width: 1024px) {
    .trailers__page{
      flex-direction: column;
      height: 100svh;
    }
  }
  @media screen and (max-width: 762px) {
    .trailers__table__body__item__img img {

      margin-bottom: 10px;
      width: 100%;
    }
  }

  
  @mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }
.modal__edit__trailer {
    position: absolute;
    width: 4%;
    z-index: 9999; 
  
    .modal-content__edit__trailer {
      position: absolute;
      right: 0;
      background: #fff;
      padding: 11px;
      border-radius: 8px;
      padding-right: 20px;
      z-index: 10000;
      gap: 5px;
      width: 179px;
  
      @include font(Inter, 500, 16px, 1.7);
      display: inline-flex;
  
      flex-direction: column;
      align-items: flex-start;
  
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  
      /* Ваші стилі для вмісту модального вікна */
    }
  
    .more-action-item {
      cursor: pointer;
    }
  }
  .d__menu__item__trailer{
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
.d__menu__item__trailer:hover{
  color: #0E91AF;
}
  .vvvv{
    position: relative;
    svg{
      cursor: pointer;
    }
  }

  .hidden {
    display: inline-block;
}

.trailer__name__year{
  display: flex;
}

