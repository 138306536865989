@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$BG-BACK-COLOR: #EFF0F2;

.routes__inner__container {
  width: 100%;
  max-width: 480px;
  background-color: $BG-BACK-COLOR;
  
}

.routes____filter__items__container {
  display: flex;
  justify-content: space-between;
}

.main__drivers__choice {
  display: flex;
  margin-bottom: 10px;

  button {
      padding: 8px 16px;
      margin-right: 10px;
      cursor: pointer;
      border: none;
      outline: none;
      background-color: #EFF0F2; /* Колір фону за замовчуванням */
      &.main__drivers__my.active {
          background-color: white; /* Колір фону для активної кнопки My drivers */
 /* Колір тексту для активної кнопки My drivers */
      }
      &.main__drivers__all.active {
          background-color: white; /* Колір фону для активної кнопки All */
/* Колір тексту для активної кнопки All */
      }
  }
}

.main__drivers__my {
  @include font(Inter, 600, 15px, normal);
  padding: 6px 30px;
  border-radius: 8px;
  background: #ffffff;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.main__drivers__all { 
  @include font(Inter, 600, 15px, normal);
  padding: 6px 30px;
  border: none;
  background-color: #ffffff;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.main__drivers__right__item {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

.main__drivers__filter {
  @include font(Inter, 400, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;


  svg {
    height: 20px;
    width: 20px;
  }

}

.main__drivers__search {
  @include font(Inter, 400, 14px, 24px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding: 5px 14px;
  outline: 0;
  height: 35px;

  input {
    border: none;
    outline: none;
    width: 120px;
    @include font(Inter, 400, 15px, 21px);
  }
}

.main__drivers__search > svg:nth-child(1) {
  height: 30px;
  width: 20px;
}

.main__drivers__panel{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 25px;
}