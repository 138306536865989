@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.DropDownOptionsAddBD {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  width: 90%;
  align-items: flex-end;

  label {
    @include font(Inter, 500, 14px, 20px);
  }

  .BtnAdd {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include font(Inter, 600, 15px, 24px);
    color: #FFF;
    border-radius: 12px;
    background: #111;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
height: 44px;
padding: 10px 128px;
justify-content: center;
    transition: ease-in 0.4s;

    &:hover {
      background: #0E91AF;
    }
  }
}

.AddValuesDropGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .inputValueType {
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
padding: 13px 14px 12px 14px;
align-items: center; 
    @include font(Inter, 600, 16px, 24px);
    height: 45px;
  }
label{
margin: 5px;
color: #344054;
}

}

.custom-select.admin{
  background-color: white;
  border-radius: 8px;
  padding: 13px 14px 12px 14px; 
  border: 1px solid #D0D5DD;
  height: 45px;
}

.options-list.admin{
  top: 50px;
width: 257px;
right: 0px;
}