@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}


.adminUserContainer {
  height: 100vh;
  overflow: auto;
  width: 100%;
  padding: 45px 38px;
  background: #EFF0F2;

  .admimUsersTitle {
    @include font("Inter", 700, 25px, 29px);
  }

  .adminUsersHeadInfo {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    margin-bottom: 20px;

    .adminUsersHeadRight{
      display: flex;
      gap: 15px;
    }

    .adminUsersHeadAddUser {
      @include font("Inter", 500, 15px, 24px);
      border-radius: 7px;
      background: #FFF;
      padding: 5px 12px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }

    .adminUsersHeadSearch {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #FFF;
      padding: 5px 14px;

      input {
        border: none;
        color: #888;
        @include font("Inter", 400, 14px, 24px);

        &:focus {
          outline: none;
        }
      }
    }
  }
}