@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.editing{
  input{
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid  #D0D5DD;
    background:  #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

}
.DropDownOptionsTruckContainerValue {
  width: 50%;

  .TrailerAddValue {
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    background: #FFF;
    cursor: pointer;
  }

  .DropDownOptionsTruckValue {
    display: flex;
  }
}

