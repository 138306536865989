@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}


.modal__edit__driver__info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  z-index: 3;

  .close__modal__edit__driver {
    cursor: pointer;
  }

  .selected-option {
    @include font('Inter', 600, 15px, 24px);
    color: #101828;
  }

  .modal-content__edit__driver__info {

    .modal__edit__driver__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .modal__edit__driver__title {
        @include font('Inter', 600, 16px, 24px);

      }
    }

    .modal__edit__driver__body {

      .form__group__edit__driver__info {

        .form__label__edit__driver__info{
          display: flex;
          justify-content: space-between;


          .validation-error{
            color: #FF3939;
            @include font('Inter', 500, 13px, 24px);
          }
        }
        input {
          width: 100%;
          border-radius: 8px;
          border: 1px solid #D0D5DD;
          background: #FFF;
          padding: 13px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          @include font('Inter', 600, 15px, 24px);
          margin-bottom: 10px;
        }

        label {
          @include font('Inter', 500, 14px, 20px);
        }
      }

      .btn__edit__cancel__driver__profile {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFF;
        padding: 13px;
        cursor: pointer;
        transition: ease 0.5s;
        @include font('Inter', 500, 16px, 24px);

        &:hover {
          background: #BBB;
        }
      }

      .btn__edit__save__driver__info {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #111;
        color: #FFFFFF;
        @include font('Inter', 600, 15px, 24px);
        padding: 10px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        transition: ease 0.5s;
        cursor: pointer;

        &:hover {
          background: #0E91AF;
        }
      }

      .btn__group__edit__driver__profile {
        display: flex;
        flex-direction: column;
        gap: 14px;


      }
    }
  }
}

.modal-content__edit__driver__info {
  border-radius: 8px;
  background: #F4F4F4;
  padding: 14px 20px;
  width: 520px;
}


.driver__profile {
  width: 100%;
  height: 36px;
  @include font(Inter, 600, 15px, 24px);
  transition: all 0.2s ease-in-out;
  padding: 13px 14px 12px 14px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


.driver__profile:hover {
  border-color: #636366;
}


.driver__profile__select {
  width: 100%;
  right: 0px;


}

.option-item .checkbox {
  display: flex;
}