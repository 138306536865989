@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }

.hh_slider {
  height: 90%;
}

.one__truck__table__body__item__img {
  height: 240px;
}

.fl__slider {
  display: flex;
  align-items: center;
}

.custom-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

}

.custom-slider__slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
}

.slide img {
  width: 100%;
  height: auto;
}


.slider-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}


.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  transition: all 0.4s;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5cc3b6;
  margin: 0 5px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;
}

.swiper-pagination-bullet.active {
  background-color: #000;
  opacity: 1;
  transition: all 0.4s;
}

//style slider default

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}

.swiper-button-next, .swiper-button-prev {
  border-radius: 0px 6px 6px 0px;
  background: rgba(205, 205, 205, 0.80);
}

.swiper-slide {
  img {
    object-fit: cover;
    height: 100%;
    border-radius: 8px;
  }
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 9px;
  margin-top: 15px;
  height: 60px;
}

.thumbnail{
  width: 70px;

    border-radius: 8px;
    overflow: hidden;

  img{
  object-fit: cover !important;
  }

}



.modal__slider__photo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  user-select: none;
}
.driver__profile__page{
  .modal__slider__photo {
    width: 100%;
    height: 100%;
  }
}

.modal-content__slider__photo {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.close__slider__photo {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  font-size: 30px;
  color: white; 
  cursor: pointer;

}


.modal-content__slider__photo img {
  width: 100%;
  height: 60vh;
  object-fit: contain;

}


.swiper-wrapper{
  z-index: 0;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-pagination{
    transition: all 0.4s;
  z-index: 0;
}
.swiper-button-next,
.swiper-button-prev{
   background: none;
   color: aliceblue;
}

img.modal__slide__show__img {
  height: 120%;
}

.thumbnail {
  opacity: 0.7;
  border: #5cc3b6;
  transition: opacity 0.3s;
  transition: border-color 0.5s;


  &.active {
      border: 2px solid rgb(255, 255, 255); 
      opacity: 1;
  }
}

.modal__slide__show__main{
  width: initial;

}


.swiper-wrapper{
.swiper-slide{
  display: grid;
  align-content: center;
  justify-items: center;

}
}

.modal__slide__show__main{
  width: fit-content;
}


.swiper__button_container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin: 0px 60px 0px 60px;
}

.swiper__button_container{
  svg{
    // margin-right: 15px;
    margin-top: 10px;
    width: 20px;
    height: 20px;
    color: white;
  }
}

.close__slider__photo{
  z-index: 999;
}

.swiper-wrapper.driver {
    height: 600px;
    border-radius: 8px;
    img{
        height: 80% !important;
    }
  }
  .swiper-slide.driver.fl__slider.swiper-slide-active{
background-color: white;
transition: background-color 0.4s;
z-index: 1;
  }

  .custom-slider.driver{
border-radius: 8px;
  }

  .swiper-button-prev.driver,
  .swiper-button-next.driver{
    background-color: #D8D8D8;
    padding: 0;
    margin: 0px 20px 0px 20px;
    border-radius: 100px;

    height: 4.5%;
  }
  .outer {
    margin-top: 13px;
    position: relative;
    width: 100%;
    align-items: center;
    align-content: center;
    display: flex;
    height: 20px;
    /*! left: -10px; */
    top: 30px;
  

  .swiper-pagination.driver{
    top: 0px;
    position: relative;
    
  }
}
.outer__container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #191D23;
    @include font(Inter, 600, 14px, 24px);
}

.outer__tools{
    display: flex;
    gap: 10px;
    svg{
    width: 18px;
    height: 18px;
    }
}
.download__tools{
cursor: pointer;
z-index: 2;
}
.swiper-pagination.inner__tools {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    svg{
        width: 23px;
        height: 23px;
    }
    .inner__zoom{
        display: flex;
        gap: 10px;
        position: relative;
        left: 11px;
        svg{
          cursor: pointer;
        }
    }
}

.swiper-slide.fl__slider.swiper-slide-active img {
  object-fit: contain !important;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  width: 80%;

}
.no-image {
  align-content: center;
  text-align: center;
}


.custom-scroll {
  // max-height: 100vh; // Adjust the maximum height as needed
  overflow-y: auto;
}

.body-scroll-enabled {
  overflow: auto;
}

.inner__fulscreen{
cursor: pointer;
}
