@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}
 

.help__components__title{
  @include font(inter, 500, 20px, normal);
  margin-bottom: 27px;
  display: flex;
gap: 10px;
}


.help__components__container{
  width: 100%;
  padding: 45px 17px;
  background: #EFF0F2;
}

.mb__dr{
  margin-bottom: 45px;
}
.form__group__help__driver{
  display: flex;
  flex-direction: column;

  label{
    @include font(inter, 600, 15px, normal);
  }

  textarea{
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    resize: none;
    outline: 0;
    padding: 12px 8px;
    @include font(inter, 300, 14px, normal);
  }
}

 
.form__btn__group__help__driver{
  display: flex;
  flex-direction: column;
gap: 12px;

  .btn__create__help__dr{
    @include font(inter, 600, 16px, normal);
    color: #FFF;
    border-radius: 10px;
    background: #111;
    padding: 14px 92px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
transition: all 0.1s ease-in-out;

  }
  .btn__create__help__dr:hover{
  background-color: #0E91AF;
  }
  .btn__cancel__help__dr{
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    color: #111;
    @include font(inter, 500, 16px, normal);
    padding: 14px 133px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
  .btn__cancel__help__dr:hover{
    background-color: #BBBBBB;
  }
}

// custom input

.custom-file-input {
  position: relative;
  overflow: hidden;
  color: #0E91AF;
}

.custom-file-input input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;

}

.upload-label{
  @include font(inter, 600, 16px, normal);
  position: relative;
  background-color: #0E91AF;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 127px;
}

.preview__photo__driver__report{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  gap: 16px;
  width: fit-content;
  margin-bottom: 20px;
  
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

}
.delete__preview{
  position: relative;
    text-align: end;
    top: 25px;
    right: 10px;
    color: black;
    height: 20px;
    z-index: 4;
    svg{
      height: 25px;
      width: 25px;
      background-color: white;
      border-radius: 100%;
      cursor: pointer;
    }

}
.additional__grid__div{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.preview__photo__container{
  width: 200px;
  height: 200px;
}
.title__add__report{

    @include font(inter, 600, 16px, normal);

}

.btn__create__help__dr{
  @include font(inter, 600, 16px, normal);
  background-color: white;

}

.svg__add__report__driver{

    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  svg{
    width: 62px;
    height: 62px;
  }
}

.btn__create__help__dr__b{
  width: 160px;
  height: 35px;
  background-color: white;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  @include font(inter, 600, 16px, normal);
  transition: all 0.1s ease-in-out;
}
.btn__create__help__dr__b:hover{
  background-color: #0E91AF;
  color: white;
  border: none;
}
.btn__container{
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 40px;
}

.close__button__help{
  width: 100%;
  display: flex;
    justify-content: flex-end;
    svg{
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
}
 
span.closel__add__report__driver{
  position: relative;
  bottom: 15px;
  left: 15px;
}

.help__components__title__svg{
  cursor: pointer;
  svg{

  width: 25px;
  height: 25px;
  background-color: white;
border-radius: 8px;
}
}
.error-message__help{
margin-bottom: 30px;
border-radius: 6px;
padding: 12px 32px 12px 14px;
color: #FF3939;
gap: 6px;
@include font("Inter", 600, 14px, 24px);
}