@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }
  
  $BG-BACK-COLOR: #EFF0F2;
  $BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
  $COLOR-BLACK-DIMMED: #454545;

.table__wrapper__dispatchers{

    margin-inline: auto;
    
    table{
    border-collapse: collapse;
    background-color: $BG-BACK-COLOR-TABLE-TITLE;
      border-radius: 10px;
      width: 100%;
      th,
      td{
        padding: 10px 5px 10px 20px;
    width: 15%;
    @include font(Inter, 500, 15px, 21px);
    
      }
      th{
        text-align: left;
        padding: 15px 50px 15px 20px;
        @include font(Inter, 600, 14px, 21px);
      
      border-radius: 10px;
      }
      td{
      border-bottom: 0.6px solid #C9C9C9;
      background: #FFF;
      cursor: pointer;
      padding: 25px 50px 25px 20px;
      
      }
     tbody{
      tr:hover{
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
      z-index: 0;
      td{
        
      }
      }
    }
      
    
      .table__tools__dispatchers{
        text-align: end;
        svg{
          width: 20px;
          height: 20px;
        }
      }
      thead{
      background-color: $BG-BACK-COLOR-TABLE-TITLE;
      padding: 20px 50px 20px 20px;
      
      border-radius: 10px;
      }
      .table__sort__dispatchers{
        cursor: pointer;
        svg{
          width: 30px;
          height: 20px;
    
        }
        .table__sort__less{
          height: 15px;
        }
        .table__sort__more{
          height: 20px;
        }
      }
    }
    tbody:hover > :not(:hover){
      opacity: 0.7;
    
      
    }
    .table__container__dispatchers{
      max-width: 100%;
    }
    }

    .nested__row{
      border: none;
      td{
        border: none;
        text-align: center;
      }
    }
    .nested__cell{
      border: none !important;
      border-bottom: 0px solid #C9C9C9 !important;
    }
.table__container__dispatchers{
  td{
      svg {
      width: 20px;
      height: 20px;
      position: relative;
      top: 5px;

      }
    } 
}
    