@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$INPUT-COLOR-LOGIN: #000;

.form__group__add__customer {
  display: flex;
  flex-direction: column;
  button{
    @include font(Inter, 600, 14px, 21px);
    transition: all 0.1s ease-in-out;
  }
  button:hover{
    background-color: #0E91AF;
    color: white;
  }
  .error-message{
    color: #FF3939;
  }
  
}

.form__group__cancel__customer__btn{
    button{
      @include font(Inter, 600, 14px, 21px);
      transition: all 0.1s ease-in-out;
    }
    button:hover{
      background-color: #BBBBBB;
      
    }
  

}
.error-input { border: 1px solid #FF3939 !important;
}
.heading__modal__customer {
  display: flex;
  justify-content: space-between;
}

.form__group__add__customer {
  margin: 10px 0;
}

.form__group__add__customer {
  label {
    @include font(Inter, 500, 14px, 21px);
    display: flex;
    justify-content: space-between;
  }

  input {
    padding: 13px 14px 12px 14px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @include font(Inter, 600, 16px, 24px);
  }
}

.form__group__add__customer {

  input {

    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: white;
    outline: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @include font(Inter, 400, 16px, 24px);
    color: $INPUT-COLOR-LOGIN;
    cursor: pointer;
    transition: all 0.2s;

  }

  input:hover {
    border-color: #636366;
  }
}

.form__group__add__customer__btn {
  display: flex;
  flex-direction: column;

  button {
    padding: 10px 128px;
    @include font(Inter, 600, 15px, 24px);
    border-radius: 12px;
    background: #111;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;
    cursor: pointer;
    border: none;
    transition: background 0.3s;

    &:hover {
      background: #0E91AF;
    }
  }
}

