@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modal-overlay__change__driver__load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content__change__driver__load  {
  background: #F4F4F4;
  padding: 14px 20px;
  border-radius: 8px;
  max-width: 360px;
  width: 100%;

  .btn__change__driver__container{
    display: flex;
    flex-direction: column;
    gap: 14px;

    .btn__change__driver__change{
      display: flex;
      justify-content: center;
      padding: 14px;
      @include font(Inter, 600, 15px, 24px);
      color: #FFF;
      border-radius: 12px;
      background: #111;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      transition: all 0.3s ease;

      &:hover{
        background: #0E91AF;
      }
    }
    .btn__change__driver__cancel{
      display: flex;
      justify-content: center;
      padding: 14px;
      @include font(Inter, 500, 15px, normal);
      color: #111;
      border-radius: 10px;
      background: #FFF;
      transition: all 0.3s ease;
      &:hover{
        background: #BBB;
      }
    }
  }

  .change__driver__heading{
    display: flex;
    justify-content: space-between;

    .title__change__driver{
      @include font(Inter, 600, 16px, 24px);
    }
  }
}




.custom-select.doc_category{
  width: 100%;
  background-color: white;
  border: solid 1px #D0D5DD;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  
}

.form-group_add__document__driver{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

}
.selected-options.doc__category{
  justify-content: space-between;
}

.selected-option.doc__category{
  width: unset;
}
.selected-option.doc__font{
  @include font(Inter, 500, 16px, 24px);
  color: #101828;
}

.modal-container_add_driver__doc{
  span{
    @include font(Inter, 500, 14px, 20px);
    color: #344054;
    
  }
}

.options-list.doc{
  right: 0px;
  width: 100%;
}
.doc_category__main{
  height: 40px;
  width: 100%;
  background-color: white;
  border: solid 1px #D0D5DD;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
.doc_category__main:hover{
  border-color: black;
} 
.dispatcher__select{
  width: 140%;
}

.change__driver{
  width: 100%;
  border-radius: 8px;
border: 1px solid  #D0D5DD;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
margin-bottom: 20px;
  margin-top: 30px;
  padding: 12px 14px;
  height: 45px;
}

.change__driver__svg{
  justify-content: end;
}

options-list.change__driver__options{
  top: 50px;
width: 317px;
right: 1px;
}