@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.modal-overlay__add__load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center; 
}

.modal-content__add__load {
  width: 100%;
  max-width: 713px;
  background: #F4F4F4;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  max-height: 90vh;

  .messageWhyCustomerBlackList{
    border-radius: 6px;
    background: #FFF;
    padding: 12px 32px;
    margin-bottom: 14px;
    color: #0E91AF;
    @include font('Inter', 600, 14px, 24px);
  }

  .modal__add__load__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .add__load__title__modal {
      @include font('Inter', 600, 16px, 24px);
    }
  }
}

.add_load__form {
  display: flex;
  justify-content: space-between;

  .custom-select {
    width: 50px;
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .add__load__to {
    display: flex;
    justify-content: space-between;

    .add__load__to__time {
      display: flex;
      justify-content: flex-end;


      input {
        width: 44%;


        border-color: #D0D5DD;
        @include font('Inter', 600, 16px, 24px);
      }
    }

    .add__load__to__date {
      display: flex;
      background-color: white;
      padding: 5px 10px;
      gap: 10px;
      border-style: solid;
      border-width: 1px;
      border-radius: 8px;
      border-color: #D0D5DD;
      width: 215px;
      margin-right: 10px;

      input {
        border: none;
        width: 80%;
        @include font('Inter', 600, 16px, 24px);
        text-align: center;
      }
    }


  }

  .add__load__from {
    display: flex;
    margin-bottom: 12px;


    .add__load__from__time {
      display: flex;
      justify-content: flex-end;

      input {
        width: 50%;
        @include font('Inter', 600, 16px, 24px);
      }
    ;
    }
  }

  .sizes__container {
    display: flex;
    gap: 16px;
    height: 41px;

    input {
      border-style: none;
    }
  }

  .add__load__weight__input {
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      @include font('Inter', 400, 16px, 24px);
    }
  }

  .add__load__length__input {
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      @include font('Inter', 400, 16px, 24px);
    }
  }

  .add__load__height__input {
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      @include font('Inter', 400, 16px, 24px);
    }
  }

  .sizes__weight {
    display: flex;
    align-items: center;
    border: 1px solid #D0D5DD;
    border-radius: 6px;

    input {
      @include font('Inter', 400, 16px, 24px);
    }

    .weight__block {
      padding: 8px 8px;
      @include font('Inter', 600, 16px, 24px);
    }
  }

  .sizes__length {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #D0D5DD;

    input {
      @include font('Inter', 400, 16px, 24px);
    }

    .length__block {
      padding: 8px 11px;
      @include font('Inter', 600, 16px, 24px);
    }
  }

  .sizes__height {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #D0D5DD;

    input {
      @include font('Inter', 400, 16px, 24px);
    }

    .height__block {
      padding: 8px 10px;
      @include font('Inter', 600, 16px, 24px);
    }
  }

  .form__group__add__load__time {
    display: flex;


  }

  .add__load__from__date {
    display: flex;
    background-color: white;
    padding: 5px 10px;
    gap: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: #D0D5DD;
width: 215px;
margin-right: 10px;

    input {
      width: 80%;
      border: none;
      text-align: center;
    }
  }

  .title__client__contacts {
    @include font('Inter', 600, 16px, 24px);
    margin-bottom: 10px;
  }

  .form__group__add__load__btn {
    display: flex;
    gap: 14px;
    flex-direction: column;

    .add__load__btn {
      @include font('Inter', 600, 15px, 24px);
      border: none;
      padding: 12px;
      color: #FFF;;
      border-radius: 8px;
      background: #111;
      cursor: pointer;
      transition: background 0.5s ease;

      &:hover {
        background: #0E91AF;
      }
    }

    .cancel__load__btn {
      @include font('Inter', 600, 16px, normal);
      border: none;
      padding: 15px 133px 14px 133px;
      border-radius: 8px;
      background: #FFF;
      cursor: pointer;
      transition: background 0.5s ease;
      &:hover{
        background: #BBB;
      }
    }
  }

  .add__load__status {
    display: flex;

    label {
      display: block;
      margin-bottom: 8px;
      cursor: pointer;
      padding: 0 17px;
      border: 1px solid transparent;


      input {
        display: none;
      }
    }
  }


  .add__load__status {
    display: flex;
    justify-content: space-between;
    @include font('Inter', 500, 13px, 24px);
  }

  .modal__add__load__form__left {
    width: 48%;
  }

  .modal__add__load__form__right {
    width: 48%;
  }

  .title__add__load {
    @include font('Inter', 600, 16px, 24px);
  }

  .form__group__add__load {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .label__error {
      display: flex;

      label {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }


      .error-message {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FF3939;
        @include font('Inter', 500, 13px, 24px);
      }
    }

    .customers__search__component {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      input {
        border: none;

        &:focus {
          outline: none;
        }
      }

    }


    label {
      display: flex;
      justify-content: space-between;
      @include font('Inter', 500, 14px, 20px);
      color: #344054;

      .error-message{
        color: #FF3939;
      }
    }

    input {
      @include font('Inter', 600, 16px, 24px);
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      padding: 10px 14px;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
}


.modal-content__add__load {
  input:not([type="checkbox"]) {
    &:focus {
      outline-color: #111 !important;
      outline: none !important;
      outline-width: 1px !important;
    }
  }
}

.modal__close__load {
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.add_load__form .add__load__from{
justify-content: space-between;
}
.add_load__form .add__load__from .add__load__from__time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*! align-content: center; */
}

.add_load__form .add__load__from .add__load__from__time input {
  width: 50%;
  height: 100%;
  border-width: 1px;
  border-color: #D0D5DD;
  text-align: center;

}

.am_pm__toggle {
  height: 100%;
  align-items: center;
  background-color: #EAEBEC;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-style: solid;
  border-left-style: none;
  border-width: 1px;
  border-color: #D0D5DD;
  padding: 10px 6px;
  padding: 12px 4px;
  @include font('Inter', 600, 14px, 21px);
  cursor: pointer;
}

.add__load__from__date__label {
  @include font('Inter', 400, 16px, 24px);
  display: flex;
  align-items: center;
  width: 35px;
}

.add__load__from__date {
  input {
    @include font('Inter', 600, 16px, 24px);
  }
}

.form_group__remember__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // margin-bottom: 10px;
  

  .form__group__remember__checkbox {
    width: 100%;

    label {
      cursor: pointer;
    }
  }
}

.add__load__from__to {
  margin-bottom: 10px;
  width: 100%;
}

.form__group__add__load__sizes {

  label {

    @include font('Inter', 500, 14px, 20px);
    color: #344054;
  }
}



.add_load__form .add__load__from__date input {
  border: none;
  
}

.add_load__form .add__load__from .add__load__from__time {
  align-items: center;
}

.add_load__form .add__load__from .add__load__from__time input {
  height: 100%;
}

.add_load__form .add__load__from .add__load__from__time input {
  width: 50%;
  width: 44%;
}

.add_load__form .add__load__to .add__load__to__time input {
  text-align: center;
  
}

.add_load__form .sizes__weight input {
  text-align: center;
}

.add_load__form .sizes__length input {
  text-align: center;
}

.add_load__form .sizes__height input {
  text-align: center;
}

.form_group__remember__container {
  

}

.react-time-picker{
  height: 36px;
}

.react-time-picker__wrapper{
  border: none;
}
.react-time-picker__inputGroup__input, .react-time-picker__inputGroup__amPm{
@include font('Inter', 600, 14px, 24px);
}
.react-time-picker__inputGroup__input, .react-time-picker__inputGroup__amPm:focus{
  outline: none ; 
  border: none ;
}

.react-time-picker__inputGroup{
  background-color: white;
  border: solid 1px #D0D5DD;
    border-radius: 8px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}


.react-time-picker__inputGroup__input:invalid{
  background: none;
}

.react-time-picker__inputGroup__input .react-time-picker__inputGroup__minute .react-time-picker__inputGroup__input--hasLeadingZero,
.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero{
  @include font('Inter', 600, 16px, 24px);
}
.react-time-picker__inputGroup__divider{
  @include font('Inter', 600, 16px, 24px);
}
.react-time-picker__inputGroup__amPm{
  background-color: #EAEBEC;
    height: 100%;
    width: 50%;
    position: relative;
    top: 0px;
    left: 2px;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
