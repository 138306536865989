.status__note__driver__wrapper{
    position: fixed;
    left: 128px;
right: 0px;
bottom: 0;
}

@media screen and (max-width: 1024px) {
    .status__note__driver__wrapper{
        position: fixed;
        left: 0;
        width: 100%;
    }
}