.hh_slider {
  height: 90%;
}

.one__truck__table__body__item__img {
  height: 240px;
}

.fl__slider {
  display: flex;
  align-items: center;
}

.custom-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

}

.custom-slider__slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
}

.slide img {
  width: 100%;
  height: auto;
}


.slider-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}


.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5cc3b6;
  margin: 0 5px;
  cursor: pointer;
  opacity: 1;
}

.swiper-pagination-bullet.active {
  background-color: #000;
  opacity: 1;
}

//style slider default

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}

.swiper-button-next, .swiper-button-prev {
  border-radius: 0px 6px 6px 0px;
  background: rgba(205, 205, 205, 0.80);
}

.swiper-slide {
  img {
    object-fit: cover;
    height: 100%;
    border-radius: 8px;
  }
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 9px;
  margin-top: 15px;
  height: 60px;
}

.thumbnail{
  width: 70px;

    border-radius: 8px;
    overflow: hidden;

  img{
  object-fit: cover !important;
  }

}



.modal__slider__photo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  user-select: none;
}


.modal-content__slider__photo {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.close__slider__photo {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  font-size: 30px;
  color: white; 
  cursor: pointer;

}


.modal-content__slider__photo img {
  width: 100%;
  height: 60vh;

}


.swiper-wrapper{
  z-index: 0;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-pagination{
  z-index: 0;
}
.swiper-button-next,
.swiper-button-prev{
   background: none;
   color: aliceblue;
}

img.modal__slide__show__img {
  height: 120%;
}

.thumbnail {
  opacity: 0.7;
  border: #5cc3b6;
  transition: opacity 0.3s;
  transition: border-color 0.5s;


  &.active {
      border: 2px solid rgb(255, 255, 255); 
      opacity: 1;
  }
}

.modal__slide__show__main{
  width: initial;

}

.download-button{


}
.swiper-wrapper{
.swiper-slide{
  display: grid;
  align-content: center;
  justify-items: center;

}
}

.modal__slide__show__main{
  width: fit-content;
}


.swiper__button_container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.swiper__button_container{
  svg{
    // margin-right: 15px;
    margin-top: 10px;
    width: 20px;
    height: 20px;
    color: white;
  }
}

.close__slider__photo{
  z-index: 999;
}

@media screen and (max-width: 584px) {
  .swiper-slide {
    img {
      object-fit: contain;

    }
   

  }
   .modal-content__slider__photo {
      max-width: 100%;
    }
    .modal__slider__photo{
      top: -60px;
    }
}