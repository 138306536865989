@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.driver__single__route {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.1s ease;
  margin-bottom: 20px;

  &:last-child {
    //margin-bottom: 150px;
  }
}


.loadMoreLoadsDriver {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background: #111;
  color: #FFFFFF;
  padding: 15px 0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  @include font(inter, 500, 13px, 24px);
  margin-bottom: 80px;
  cursor: pointer;
}

.driver__single__route:hover {
  box-shadow: 20px 10px 20px 0px rgba(12, 18, 29, 0.05);
}

.driver__single__route__statuses {
  @include font(inter, 500, 13px, 24px);
  display: flex;
  gap: 10px;
}

.driver__single__route__first__status {
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  padding: 0px 17px;
}

.driver__single__route__second__status {
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  padding: 0px 17px;
}

.start__point__details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.start__point__title {
  @include font(inter, 600, 16px, normal);
}

.start__point__description {
  margin-left: 15px;
  color: #676767;
  @include font(inter, 500, 13px, normal);
}

.start__point__date {
  @include font(inter, 500, 15px, normal);
}

.start__point__time {
  @include font(inter, 500, 13px, normal);
  color: #676767;
}

.end__point__title {
  @include font(inter, 600, 16px, normal);
}

.end__point__description {
  margin-left: 20px;
  color: #676767;
  @include font(inter, 500, 13px, normal);
}

.end__point__date {
  @include font(inter, 500, 15px, normal);
}

.end__point__time {
  @include font(inter, 500, 13px, normal);
  color: #676767;
}

.start__point {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  border-left-style: dashed;
  border-width: 2px;
  padding-bottom: 15px;
}

.start__point__overall__date {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.end__point {
  display: flex;
  justify-content: space-between;
}

.end__point__details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.end__point__overall__date {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-right: 50px;

}

.date__camera__container {
  display: flex;
  gap: 30px;

  svg {
    height: 21px;
    width: 21px;

  }
}

.grid__wrapper__routes {
  border-width: 3px;
}

.start__point__icon__title {
  display: flex;

}

.end__point__icon__title {
  display: flex;

}

.start__point__icon {
  svg {
    position: relative;
    right: 9px;
    background-color: white;
    stroke-width: 20px;
  }
}

.end__point__icon {
  svg {
    position: relative;
    right: 9px;
    background-color: white;
    width: 20px;
    height: 20px;

  }
}

.route__driver__main {
  padding: 20px 15px;
  overflow-y: auto;
  height: calc(90svh - 150px);
}

.detailed__load__information {
  display: flex;
  padding: 1px 0px 15px 19px;
  border-left-style: dashed;
  border-width: 2px;
  gap: 30px;
}

.detailed__load__label {
  @include font(inter, 300, 15px, normal);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detailed__load__data {
  @include font(inter, 600, 15px, normal);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.camera__link {
  color: inherit;
  text-decoration: none;
}

.driver__single__route__reason__container {
  border-top: solid 1px #C2C2C2;
  margin-top: 10px;
  padding-top: 10px;
}

.driver__single__route__reason__title {
  @include font(inter, 500, 13px, normal);
  color: #676767;
}

.driver__single__route__reason__comment {
  @include font(inter, 400, 13px, normal);
  color: #101828;
}

.gray__cam {
  svg {
    color: #9c9c9c;
  }
}