.modal-overlay__reset__password {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content__reset__password {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.success-message__reset__password {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

.close-modal__reset__password {
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  align-self: flex-end;
}

.close-modal__reset__password:hover {
  color: #555;
}
