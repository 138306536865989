@mixin font($font, $weight, $size, $height) {
  font-family: $font;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

.analytics__right__item {
  align-items: center;
}

.analytics__export {
  @include font(Inter, 600, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: solid 1px #0E91AF;
  color: #0E91AF;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.analytics__export:hover {
  background-color: #0E91AF;
  color: #fff;
}

.analytics__select {
  @include font(Inter, 600, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 2px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 190px;
  transition: all 0.1s ease-in-out;
}

.analytics__icon svg {
  width: 20px;
  height: 20px;
  stroke-width: 0.5;
}

.analytics__icon {
  display: flex;
  justify-content: center;
}

$BG-BACK-COLOR: #EFF0F2;
$BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
$COLOR-BLACK-DIMMED: #454545;

.analytics__page {
  display: flex;
  width: 100%;
  background-color: $BG-BACK-COLOR;
}


.analytics__container {
  padding: 45px 38px 39px 38px;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow-x: auto;
}

.analytics__title {
  @include font(Inter, 700, 25px, normal);
}

.c__n {
  display: flex;
  gap: 20px;
}

.analytics__sort {
  display: grid;
}

.analytics__panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}

.analytics__choice {
  display: flex;
  margin-bottom: 10px;
}

.analytics__right__item {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  z-index: 9;
}

.analytics___register_date {
  display: flex;
  align-items: center;

}

.analytics__sort > svg:nth-child(1) {
  width: 30px;
  height: 20px;
}

.analytics__sort > svg:nth-child(2) {
  width: 30px;
  height: 20px;
}

.analytics__approved {
  @include font(Inter, 600, 18px, normal);
  padding: 6px 51px;
  border-radius: 8px;
  background: #EFF0F2;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

td {
  width: 20%
}

.analytics__blacklist {
  @include font(Inter, 600, 18px, normal);
  padding: 6px 51px;
  border: none;
  background-color: #EFF0F2;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

}

.analytics__calendar {
  @include font(Inter, 400, 14px, 20px);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--White, #FFF);
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
}

.analytics__filter {
  @include font(Inter, 400, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;


  svg {
    height: 20px;
    width: 20px;
  }

  p {
    color: $COLOR-BLACK-DIMMED;
  }
}

.analytics__search {
  @include font(Inter, 400, 14px, 24px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding: 3.5px 14px;
  outline: 0;

  input {
    border: none;
    outline: none;
    width: 240px;
    @include font(Inter, 400, 15px, 21px);
  }
}

.analytics__search > svg:nth-child(1) {
  height: 30px;
  width: 20px;
}

.analytics__table__title {

  justify-content: space-between;
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
  padding: 0px 50px 0px 20px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.email__num {
  display: flex;
  justify-content: space-between;
  gap: 130px;
}

.email__num__header {
  display: flex;
  justify-content: space-between;
  gap: 111px;
}

.analytics__table__row {
  display: flex;
  justify-content: space-between;
  padding: 15px 50px 15px 20px;

  background: #FFF;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.analytics__table__row:hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.analytics__table__row:not(:hover) {
  opacity: 0.7;
  z-index: 1;
}

.analytics__approved.active {
  background-color: white; /* Колір для кнопки Approved у стані active */

}

.analytics__blacklist.active {
  background-color: white; /* Колір для кнопки Blacklist у стані active */

}

.analytics__table__title__item {
  width: 0%;
  padding: 8px;
  white-space: nowrap;
  @include font(Inter, 600, 14px, 21px);
  display: flex;
  align-items: center;
}

.analytics__table__body__item {
  @include font(Inter, 500, 15px, 21px);

  &:hover {
    @include font(Inter, 500, 15px, 21px);
  }
}

.analytics__table {
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
}

.analytics__sort {
  display: grid;
}

.c__n {
  display: flex;
  justify-content: space-between;
}

.actions__customer {
  position: relative;
}

.analytics__table__body__item {
  width: 1%;
  padding: 8px;

}

.analytics__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.pagination__pages > button:nth-child(1) > svg:nth-child(1) {
  width: 25px;
  height: 25px;
  display: flex;
  padding: 2px;
}

.pagination__pages > button:nth-child(3) > svg:nth-child(1) {
  width: 25px;
  height: 25px;
  display: flex;
  padding: 2px;
}

.per__page {
  display: flex;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @include font(Inter, 600, 15px, 24px);
  border-radius: 4px;
  background: var(--Neutral-N0, #FFF);
  border: none;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.modal__add__customer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content__add__customer {
  background: #F4F4F4;
  padding: 14px 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 370px;
}

.title__modal__customer {
  @include font(Inter, 600, 16px, normal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__group__cancel__customer__btn {
  margin-top: 13px;

  button {
    width: 100%;
    padding: 14px 20px;
    background: #FFF;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
}

.heading__modal__customer {
  margin-bottom: 20px;
}

.close__modal__customer {
  cursor: pointer;

  svg {
    font-size: 30px;
  }
}


.pagination__pages button {
  border-radius: 100%;
  border: none;
  background-color: #D8D8D8;
}

.pagination__pages > span {

  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  padding: 10px;
}


.more-actions-modal-analytics {
  position: relative; /* Відмова від фіксованої позиції */
  width: 4%;
  z-index: 9999;

  .more-actions-content-analytics {
    position: absolute;
    background: #fff;
    padding: 11px;
    border-radius: 8px;
    padding-right: 20px;
    z-index: 10000;
    gap: 5px;
    width: 25vh;
    right: -10px;
    //   left: -20px;
    @include font(Inter, 500, 16px, normal);
    display: inline-flex;

    flex-direction: column;
    align-items: flex-start;

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: initial;
    width: 160px;

    /* Ваші стилі для вмісту модального вікна */
  }

  .more-action-item-analytics {
    cursor: pointer;
  }
}


@media screen and (max-width: 1024px) {
  .analytics__page {
    flex-direction: column;
    height: 100svh;
  }
}

@media screen and (max-width: 890px) {
  .analytics__table {
    width: 200%;
  }
}

@media screen and (max-width: 460px) {
  .analytics__table {
    width: 300%;
  }
}

@media screen and (max-width: 979px) {
  .analytics__choice {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 554px) {
  .analytics__right__item {
    display: grid;
  }
  .analytics__panel {
    display: block;
  }
}

@media screen and (max-width: 392px) {
  .analytics__choice {
    display: grid;
  }
}

.table__wrapper__analytics {
  border-spacing: 10px;
  margin-inline: auto;

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 99%;
    align-items: center;
    justify-content: center;

    //border-radius: 10px;

    th,
    td {
      padding: 10px 5px 10px 40px;
      //   width: 15%;


    }

    th {
      text-align: left;
      padding: 15px 50px 15px 20px;
      @include font(Inter, 600, 14px, 21px);

      //border-radius: 10px;
    }

    td {

      background: #FFF;
      cursor: pointer;
      padding: 25px 50px 25px 20px;

    }

    tr {
      // vertical-align: baseline;

    }

    tbody {
      tr:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        z-index: 0;

        td {

        }
      }
    }


    .table__tools__analytics {
      text-align: end;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    thead {
      background-color: $BG-BACK-COLOR-TABLE-TITLE;
      padding: 20px 50px 20px 20px;

      border-radius: 10px;
    }

    .table__sort__analytics {
      cursor: pointer;

      svg {
        width: 30px;
        height: 20px;

      }

      .table__sort__less {
        height: 15px;
      }

      .table__sort__more {
        height: 20px;
      }
    }
  }

  tbody:hover > :not(:hover) {
    opacity: 0.7;


  }

  .table__container__analytics {
    overflow-x: auto;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
}

.opacity__less__more {
  opacity: 0.5;
}

.report__img__cont {
  margin-top: 8px;
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }

  img:hover {
    opacity: 1;
  }
}

.table__container__analytics {
  p {
    border-width: 1px;
    border-style: solid;
    text-align: center;
    width: 120px;
    display: flex;
    justify-content: center;
    border-radius: 20px;

  }

}

.analytics__driver {
  // border-right: solid 1px #AEAEAE;
  position: relative;
  @include font(Inter, 700, 16px, 20px);


}

.analytics__total {

}

.days {
  @include font(Inter, 400, 15px, 20px);
  color: #454545;

  span {
    @include font(Inter, 600, 15px, 20px);
    color: #454545;
  }
}

.a__td {


  width: 0% !important;
}

.divider {
  border-left: 1px solid #AEAEAE;
  border-right: 1px solid #AEAEAE;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 25px 0px;
}

.divider__left {
  border-left: 1px solid #AEAEAE;
  border-right: 1px solid #AEAEAE;
  position: relative;
  right: 35px;
  top: 10px;
  padding: 25px 0px;
}

.analytics__span__numb {
  position: relative;
  bottom: 40px;
  left: 30px;
  color: #0E91AF !important;
}

.analytics__total__container {
  display: flex;
  justify-content: flex-end;
  @include font(Inter, 800, 18px, 20px);
}

.analytics__total__content {
  background-color: white;
  padding: 10px;
  width: 250px;
  text-align: end;
  border-radius: 8px;
}

.analytics__no__data__logo {
  width: 100%;
  justify-content: center;
  display: flex;
}

.analytics__no__data__text {
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.driver__data__not__found.analytics {
  background-color: unset;

}