@mixin font($font, $weight, $size, $height) {
    font-family: $font, sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }

  .dis__driver__dr{
    @include font(Inter, 500, 14px, normal);
  }

  .drivers__main__component {
    display: flex;
    width: 100%;
    background: #EFF0F2;

  }
  .drivers__main__docyments__select{
    display: flex;
    background-color: white;
    padding: 5px 14px;
    border-radius: 8px;



    select{
      padding: 7px;
      border-radius: 8px;
      background: #FFF;
      border: none;
      outline: 0;
      width: 304px;
      @include font(Inter, 600, 14px, 24px);
      color: #64748B;
      option{
        @include font(Inter, 600, 14px, 24px);
        color: #64748B;
      }
    }
  }

  .drivers__main__docyments__upload{
  display: flex;
    border-radius: 8px;
    background: #FFF;
    padding: 5px 10px;
    gap: 2px;
    color: #000000;

    @include font(Inter, 600, 14px, 24px);
    cursor: pointer;
  }

  .drivers__main__driver__dis {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-left: 1px solid #D0D5DD;
    padding-left: 8px;

    .dis__label__dr {
      color: #8A898F;
      @include font(Inter, 500, 13px, normal);
    }
  }

  .drivers__main__title {
    @include font(Inter, 700, 25px, normal);
  }

  .drivers__main__component__content {
    width: 100%;
    max-width: 992px;
    margin: auto;
    overflow-y: auto;
  }
  .nav__driver{
    position: fixed;
  }
  .drivers__main__heading {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;

    .drivers__main__heading__add {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 7px;
      background: #FFF;
      padding: 5px 12px;
      @include font(Inter, 500, 15px, 24px);
      cursor: pointer;
    }
  }

  .drivers__main__driver__dispathcer {
    border-radius: 10px;
    background: #DEE0E3;;
    padding: 14px;
    height: 90vh;
    overflow-y: auto;
  }

  .drivers__main__driver__info {
    display: block;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    padding: 20px 15px;
  }

  .drivers__main__vehicle__info {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .drivers__main__vehicle__d {
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      background: #FFF;
      padding: 14px;
      @include font(Inter, 400, 14px, 24px);
      width: 474px;

      span {
        @include font(Inter, 700, 16px, 24px);
      }

    }

    .drivers__main__treiler__d {
      border-radius: 8px;
      background: #FFF;
      padding: 14px;
      width: 474px;
    }
  }

  .svg1 {
    background-color: #F2F7FA;
    border-radius: 8px;
    width: 60px;
    padding: 6px 10px 1px 10px;
  }

  .drivers__main__driver__name {
    display: flex;
    align-items: center;
    gap: 15px;
    @include font(Inter, 700, 18px, 24px);
    margin-bottom: 20px;
  }


  .drivers__main__driver__phone {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-left: 1px solid #D0D5DD;
    padding-left: 8px;

    .phone__label__dr {
      @include font(Inter, 500, 13px, normal);
      color: #8A898F;
    }

    .phone__number__dr {
      @include font(Inter, 500, 15px, normal);
    }

  }

  .drivers__main__driver__email {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-left: 1px solid #D0D5DD;
    padding-left: 8px;

    .email__label__dr {
      @include font(Inter, 500, 13px, normal);
      color: #8A898F;
    }

    .email__address__dr {
      @include font(Inter, 500, 15px, normal);
      color: #000;

    }
  }

  .drivers__main__driver__edit {
    color: #0E91AF;
    cursor: pointer;
  }

  .drivers__main__vehicle__title {
    @include font(Inter, 600, 16px, 24px);
    padding: 20px 0 10px 0;
  }

  .drivers__main__docyments__info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
    align-items: center;
  }

  .drivers__main__docyments__title__right {
    display: flex;
    gap: 10px;
  }

  .drivers__main__docyments__title__left {
    @include font(Inter, 600, 16px, 24px);
  }

  @media screen and (max-width: 1024px) {
    .drivers__main__page {
      display: block;
    }

  }

  .drivers__search__component {
    @include font(Inter, 400, 14px, 24px);
    border-radius: 8px;
    background: var(--White, #FFF);
    display: flex;
    align-items: center;
    padding: 5px 14px;
    outline: 0;

    input {
      border: none;
      outline: none;
      width: 200px;
      @include font(Inter, 400, 15px, 21px);
    }

    select {
      border: none;
      background-color: #FFF;

    }
  }

  .vehicle__upper__details {
    display: flex;
    justify-content: space-between;
  }

  .vehicle__bottom__details {
    display: flex;
    gap: 40px;
  }

  .vehicle__title__edit {
    display: flex;
    align-items: first baseline;
    justify-content: space-between;
    width: 100vh;

  }

  .vehicle__title {
      display: flex;
      align-items: center;

    span {
      @include font(Inter, 700, 16px, 24px);
    }
    svg{
  background-color: #F2F7FA;
  border-radius: 3px;
  width: 60px;
  height: 50px;
  padding: 8px;
  margin-right: 10px;
    }
  }

  .vehicle__edit {

    cursor: pointer;
    svg {
      color: #0E91AF;
      height: 20px;
      width: 20px;
    }
  }

  .truck__number {
    border-left-style: solid;
    border-left-width: 1px;
    border-color: #D0D5DD;
    padding-left: 5px;
  }

  .truck__type {
    border-left-style: solid;
    border-left-width: 1px;
    border-color: #D0D5DD;
    padding-left: 5px;
  }

  .slider-component-driver{
    z-index: 1;
  }

  @media  screen and (max-width: 1024px) {
    .drivers__main__component{
      flex-direction: column;
    }
  }

  @media screen and (max-width: 606px) {
    .drivers__main__docyments__title__left{
      text-align: center;
      margin-bottom: 10px;
    }
    .drivers__main__docyments__info{
      display: block;

    }
    .drivers__main__docyments__title__right{
      justify-content: center;
    }
  }

  @media screen and (max-width: 508px) {
    .drivers__main__docyments__title__right {
      display: flex;
      flex-direction: column;
      }
      .drivers__main__docyments__select > select:nth-child(1) {
        width: 100%;
        text-align: center;
      }

      .drivers__main__docyments__upload{
        text-align: center;
  display: flex;
  justify-content: center;
      }

  }

  @media screen and (max-width: 430px) {
    .outer {

    top: 0px !important;
    }
    .drivers__main__driver__info {
      display: block;
    }
  }



  // .custom-select-container {
  //   position: relative;
  //   width: 200px; /* Задайте ширину, яка підходить вам */
  // }

  // .custom-select {
  //   border: 1px solid #ccc;
  //   position: relative;
  //   cursor: pointer;
  // }

  // .selected-option {
  //   padding: 10px;
  // }

  // .options {
  //   display: none;
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  //   right: 0;
  //   border: 1px solid #ccc;
  // }

  // .option {
  //   padding: 10px;
  //   cursor: pointer;
  // }

  // .option:hover {
  //   background-color: #f0f0f0;
  // }

  // .option.selected {
  //   background-color: #ddd;
  // }

  .status__container{
display: flex;
gap: 8px;
span {
    @include font(Inter, 500, 13px, normal);
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
    padding: 4px 12px;

    }
  }

  .drivers__information{
    display: flex;
    gap: 58px;
  }
  .drivers__main__driver__dispathcer{
    .gotrash{
    display: none;
  }
  }

  .no-data-message{
    @include font(Inter, 600, 18px, normal);
    text-align: center;
margin-top: 200px;
  }
  .drivers__main__component__content{
    .loader{
      img{
        position: relative;
        top: 0px;
        left: 140%;
      }
    }
  }

  .none__vehicles{
    color: rgb(138, 137, 143) !important;
  }
  .drivers__main__docyment{
  .outer.main{

  }
}

.drivers__profile__docyments__expiration_date_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 0 10px 0;
}

.expiration_date_container__messsage_container{
  display: flex;
  gap: 8px;
  align-items: center;
  background: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  font-family: Inter, sans-serif;
}
.expiration_date_red{
  color: #F40000;
}
.expiration_date_yello{
  color: #F89400;
}
