@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  
}
$INPUT-COLOR-LOGIN: #000;
.photo__profile {
  img {
    width: 161px;
    height: 161px;
    border-radius: 50%;
    object-fit: cover;
    display: flex;
  }
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;


    .custom__slider{
      position: relative;
    }
    input[type=range]{
      width: 400px;
      height: 15px;
      appearance: none;
      -webkit-appearance: none;
      background-color: #111;
      outline: none;
      border-radius: 15px;
      overflow: hidden;
    }

    input[type=range]::-webkit-slider-thumb{
      appearance: none;
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      background: #00FD0A;
      color: #00FD0A;
      border-radius: 50%;
      cursor: pointer;
    }
  
button {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}


.increase__button{
  background: #dee0e3;
  border-radius: 50%;
  height: 16px;
  margin: 5px;
  padding: 2px;
}

svg{
  height: 18px;
  width: 18px;
}
}
.photo-container {
  position: relative;
}

.file-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  padding: 7px;
  background: #0E91AF;;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
}


/* Модальне вікно */
.modal__photo__edit {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}
.modal__photo__edit.show {
  display: flex;
}

/* Стилі для контейнера з Cropper */
.cropper-container {
  display: flex;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  max-width: 80%;
  max-height: 80%;
  background-color: white;
  width: 400px;
height: 270px;
border-radius: 50%;
  overflow: hidden;
}

/* Кнопка закриття модального вікна */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal__add__photo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content__add__photo {
  background: #F4F4F4;
  padding: 14px 20px;
  border-radius: 8px;
  width: 100%;
  position: relative; 
  width: 480px;
  height: 654px;
}
.button__container__photo{
  position: absolute; 
                    bottom: 20px; 
                    width: 92%;
                    
}
.title__modal__photo {
  @include font(Inter, 600, 16px, normal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__group__cancel__photo__btn {
  margin-top: 13px;

  button {
    width: 100%;
    padding: 14px 20px;
    background: #FFF;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
}

.heading__modal__photo {
  margin-bottom: 50px;
}

.close__modal__photo {
  cursor: pointer;

  svg {
    font-size: 30px;
  }
}

.form__group__add__photo{
  display: flex;
  flex-direction: column;
}

.heading__modal__photo{
  display: flex; 
  justify-content: space-between;
}

.form__group__add__photo{
  margin: 10px 0;
}

.form__group__add__photo{
  label{
  @include font(Inter, 500, 14px, 21px);
  }

  input{
    padding: 13px 14px 12px 14px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @include font(Inter, 600, 16px, 24px);
  }
}

.form__group__add__photo {

  input {
    
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: white;
    outline: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @include font(Inter, 400, 16px, 24px);
    color: $INPUT-COLOR-LOGIN;
    cursor: pointer;
    transition: all 0.2s;

  }
  input:hover{
    border-color: #636366;
  }
}

.form__group__add__photo__btn{
  display: flex;
  flex-direction: column;
  button{
    padding: 10px 128px;
    @include font(Inter, 600, 15px, 24px);
    border-radius: 12px;
    background: #111;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: white;
    cursor: pointer;
    border: none;
    transition: background 0.3s;

  &:hover {
    background: #0E91AF;
  }
  }
}

.cropper-view-box{
  position: relative;
  left: -40px;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute; 
  right: 0;
  top: 0;
}

// Input
// Input
.custom-input {
  width: 176px;
  height: 3px;
  border-radius: 9px;
  background: #DEE0E3;
  position: relative;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #1292AF;
      cursor: pointer;
      position: relative;
      z-index: 2;
      margin-top: -7px; /* Adjust to center the thumb vertically */
    }
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #1292AF;
      cursor: pointer;
      position: relative;
      z-index: 2;
      margin-top: -7px; /* Adjust to center the thumb vertically */
    }
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: calc((100% * var(--value)) - 8px);
    top: 0;
    left: 0;
    background: #1292AF;
    border-radius: 5px 0 0 5px;
    transition: width 0.3s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: calc((100% * (1 - var(--value))) - 8px);
    top: 0;
    right: 0;
    background: #DEE0E3;
    border-radius: 0 5px 5px 0;
    transition: width 0.3s ease-in-out;
  }
}
