@mixin font($font, $weight, $size, $height) {
    font-family: $font;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }
  
  $INPUT-COLOR-LOGIN: #000;
  $COLOR-BLACK-DIMMED:#344054;
  
  .form__group__add__trailer{
    display: flex;
    flex-direction: column;
  }
  
  .heading__modal__trailer{
    display: flex; 
    justify-content: space-between;
  }
  
  .form__group__add__trailer{
    margin: 10px 0;
  }
  
  .form__group__add__trailer{
    label{
    @include font(Inter, 500, 14px, 21px);
    }
  .selected-option{
    @include font(Inter, 600, 15px, 21px);
    color: #101828;
  }
    input {
      padding: 5px 14px 5px 14px;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      @include font(Inter, 600, 16px, 24px);
    }
    label{
        padding-bottom: 5px;
        color: $COLOR-BLACK-DIMMED;
      }
      select{
        padding: 8px 14px 5px 14px;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      @include font(Inter, 500, 16px, 24px);
    
      }
  }
  
  .form__group__add__trailer {
  
    input{
      
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: white;
      outline: 0;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      @include font(Inter, 400, 16px, 24px);
      color: $INPUT-COLOR-LOGIN;
      cursor: pointer;
      transition: all 0.2s;
      width: 320px;
  
    }
    select{
      
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: white;
      outline: 0;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      @include font(Inter, 400, 16px, 24px);
      color: $INPUT-COLOR-LOGIN;
      cursor: pointer;
      transition: all 0.2s;
      width: 320px;
  
    }
    input:hover{
      border-color: #636366;
    }
    select:hover{
      border-color: #636366;
    }
  }
  
  .form__group__add__trailer__btn{
    display: flex;
    flex-direction: column;
    button{
        padding: 10px 12px;
      @include font(Inter, 600, 15px, 24px);
      border-radius: 12px;
      background: #111;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
      cursor: pointer;
      border: none;
      transition: background 0.3s;
  
    &:hover {
      background: #0E91AF;
    }
    }
  }
  
  .form__modal__trailer__add{
    display: flex;
    max-height: 80vh; /* Максимальна висота модального вікна */
  overflow-y: auto;
  }
  
.form__modal__trailer__add{
    .left__right__columns{
        display: flex;
        input{
          @include font(Inter, 600, 15px, 21px);
    color: #101828;
        }
    }
}

.Comment__btns{
    display: flex;
}



.left__column{
    margin-right: 30px;
}

.form__group__add__trailer.comment{
textarea{
    height: 109px;
    resize: none;
    padding: 5px 14px 5px 14px;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      outline: 0;
      @include font(Inter, 400, 14px, 24px);
      color: $INPUT-COLOR-LOGIN;
      cursor: pointer;
      transition: all 0.2s;
      width: 320px;
      @include font(Inter, 600, 15px, 21px);
    color: #101828;
  
    }
    textarea:hover{
      border-color: #636366;
    }
}

.form__group__add__trailer.trailer{
    margin-bottom: 36px;
}

.form__group__add__trailer__btn.create{
    margin-bottom: 8px;
}
