@mixin font($font, $weight, $size, $height) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

$BG-BACK-COLOR: #EFF0F2;
$BG-BACK-COLOR-TABLE-TITLE: #DCE1E4;
$COLOR-BLACK-DIMMED: #454545;

.customers__page {
  display: flex;
  width: 100%;
  background-color: $BG-BACK-COLOR;
}


.customers__container {
  padding: 45px 38px 39px 38px;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow-x: auto;
}

.customers__title {
  @include font(Inter, 700, 25px, normal);
}

.c__n {
  display: flex;
  gap: 20px;
}

.customers__sort {
  display: grid;
}

.customers__panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}

.customers__choice {
  display: flex;
  margin-bottom: 10px;
}

.customers__right__item {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

.customers___register_date {
  display: flex;
  align-items: center;

}

.customers__sort > svg:nth-child(1) {
  width: 30px;
  height: 20px;
}

.customers__sort > svg:nth-child(2) {
  width: 30px;
  height: 20px;
}

.customers__approved {
  @include font(Inter, 600, 18px, normal);
  padding: 6px 51px;
  border-radius: 8px;
  background: #EFF0F2;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

td {
  width: 20%
}

.customers__blacklist { 
  @include font(Inter, 600, 18px, normal);
  padding: 6px 51px;
  border: none;
  background-color: #EFF0F2;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

}

.customers__add {
  @include font(Inter, 400, 14px, 20px);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--White, #FFF);
  cursor: pointer;
}

.customers__filter {
  @include font(Inter, 400, 14px, 20px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;


  svg {
    height: 20px;
    width: 20px;
  }

  p {
    color: $COLOR-BLACK-DIMMED;
  }
}

.customers__search {
  @include font(Inter, 400, 14px, 24px);
  border-radius: 8px;
  background: var(--White, #FFF);
  display: flex;
  align-items: center;
  padding: 5px 14px;
  outline: 0;

  input {
    border: none;
    outline: none;
    width: 240px;
    @include font(Inter, 400, 15px, 21px);
  }
}

.customers__search > svg:nth-child(1) {
  height: 30px;
  width: 20px;
}

.customers__table__title {

  justify-content: space-between;
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
  padding: 0px 50px 0px 20px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.email__num {
  display: flex;
  justify-content: space-between;
  gap: 130px;
}

.email__num__header {
  display: flex;
  justify-content: space-between;
  gap: 111px;
}

.customers__table__row {
  display: flex;
  justify-content: space-between;
  padding: 15px 50px 15px 20px;
  border-bottom: 0.6px solid #C9C9C9;
  background: #FFF;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.customers__table__row:hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.customers__table__row:not(:hover) {
  opacity: 0.7;
  z-index: 1;
}

.customers__approved.active {
  background-color: white; /* Колір для кнопки Approved у стані active */

}

.customers__blacklist.active {
  background-color: white; /* Колір для кнопки Blacklist у стані active */

}

.customers__table__title__item {
  width: 0%;
  padding: 8px;
  white-space: nowrap;
  @include font(Inter, 600, 14px, 21px);
  display: flex;
  align-items: center;
}

.customers__table__body__item {
  @include font(Inter, 500, 15px, 21px);

  &:hover {
    @include font(Inter, 500, 15px, 21px);
  }
}

.customers__table {
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
}

.customers__sort {
  display: grid;
}

.c__n {
  display: flex;
  justify-content: space-between;
}

.actions__customer{
  position: relative;
}

.customers__table__body__item {
  width: 1%;
  padding: 8px;
}

.customers__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}


.per__page {
  display: flex;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @include font(Inter, 600, 15px, 24px);
  border-radius: 4px;
  background: var(--Neutral-N0, #FFF);
  border: none;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.modal__add__customer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content__add__customer {
  background: #F4F4F4;
  padding: 14px 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 370px;
}

.title__modal__customer {
  @include font(Inter, 600, 16px, normal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__group__cancel__customer__btn {
  margin-top: 13px;

  button {
    width: 100%;
    padding: 14px 20px;
    background: #FFF;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  } 
}

.heading__modal__customer {
  margin-bottom: 20px;
}

.close__modal__customer {
  cursor: pointer;

  svg {
    font-size: 30px;
  }
}






.pagination__pages > span {

  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  padding: 10px;
}


.more-actions-modal {
  position: relative; /* Відмова від фіксованої позиції */
  width: 4%;
  z-index: 9999;

  .more-actions-content.customers {
    position: absolute;
    background: #fff;
    padding: 11px;
    border-radius: 8px;
    padding-right: 20px;
    z-index: 10000;
    gap: 5px;
    width: 25vh;
    left: -10vh;
    top: -30px;
    @include font(Inter, 500, 16px, normal);
    display: inline-flex;

    flex-direction: column;
    align-items: flex-start;

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: initial;
    width: 160px;

    /* Ваші стилі для вмісту модального вікна */
  }

  .more-action-item {
    cursor: pointer;
    transition: all 0.1s ease;
  }
  .more-action-item:hover {
    color: #0E91AF;
  }
}


@media screen and (max-width: 1024px) {
  .customers__page {
    flex-direction: column;
    height: 100svh;
  }
}

@media screen and (max-width: 890px) {
  .customers__table {
    width: 200%;
  }
}

@media screen and (max-width: 460px) {
  .customers__table {
    width: 300%;
  }
}

@media screen and (max-width: 979px) {
  .customers__choice {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 554px) {
  .customers__right__item {
    display: grid;
  }
  .customers__panel {
    display: block;
  }
}

@media screen and (max-width: 392px) {
  .customers__choice{
    display: grid;
  }
}

.table__wrapper__customers{

margin-inline: auto;

table{
border-collapse: collapse;
background-color: $BG-BACK-COLOR-TABLE-TITLE;
  border-radius: 10px;
  width: 100%;
  th,
  td{
    padding: 10px 5px 10px 20px;
width: 15%;
@include font(Inter, 500, 15px, 21px);

  }
  th{
    text-align: left;
    padding: 15px 50px 15px 20px;
    @include font(Inter, 600, 14px, 21px);
  
  border-radius: 10px;
  }
  td{
  border-bottom: 0.6px solid #C9C9C9;
  background: #FFF;
  cursor: pointer;
  padding: 25px 50px 25px 20px;
  
  }
tbody{
  tr:hover{
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 0;

  }
  tr{
    transition: all 0.2s ease-in-out;
  }
}
  

  .table__tools__customers{
    text-align: end;
    svg{
      width: 20px;
      height: 20px;
    }
  }
  thead{
  background-color: $BG-BACK-COLOR-TABLE-TITLE;
  padding: 20px 50px 20px 20px;
  
  border-radius: 10px;
  }
  .table__sort__customers{
    cursor: pointer;
    svg{
      width: 30px;
      height: 20px;

    }
    .table__sort__less{
      height: 15px;
    }
    .table__sort__more{
      height: 20px;
    }
  }
}
tbody:hover > :not(:hover){
  opacity: 0.7;

  
}
.table__container__customers{
  max-width: 100%;
}
}

.opacity__less__more {
  opacity: 0.5;
}
